import { CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Stack, useTheme } from '@mui/material';
import { CloseCircle } from 'iconsax-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Organization, appEnv, useAdmin } from '../../../api';
import { axios } from '../../../api/axios-base';
import { AdminOrganizationSelect, Button, PageBody, PageContainer, PageHeader, useSelectedOrganization } from '../../../components';

const useAdminData = (selectedOrganization: Organization | null) => {
  const { organizations, fetchOrganizations, fetchPayrollConfig, activatePayroll: activatePayrollApi, payrollConfig: orgPayrollConfigs } = useAdmin();

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });
  }, [fetchOrganizations]);

  useEffect(() => {
    if (selectedOrganization?.hasPayroll) {
      fetchPayrollConfig(selectedOrganization.id!).catch((e) => {
        throw e;
      });
    }
  }, [selectedOrganization, fetchPayrollConfig]);

  const activatePayroll = useCallback(async () => {
    if (!selectedOrganization) {
      return;
    }

    await activatePayrollApi(selectedOrganization.id!);

    await Promise.all([fetchOrganizations(), fetchPayrollConfig(selectedOrganization.id!)]);
  }, [activatePayrollApi, selectedOrganization, fetchOrganizations, fetchPayrollConfig]);

  const payrollConfig = useMemo(() => {
    if (!selectedOrganization) {
      return null;
    }

    return orgPayrollConfigs[selectedOrganization.id!];
  }, [selectedOrganization, orgPayrollConfigs]);

  return {
    organizations,
    activatePayroll,
    payrollConfig,
  };
};

function ActivatePayroll({ selectedOrganization }: { selectedOrganization: Organization | null }) {
  const { activatePayroll } = useAdminData(selectedOrganization);

  return (
    <Stack justifyContent='center' alignItems='center' style={{ flex: 1 }}>
      <span>Payroll is Inactive</span>
      <Button variant='contained' color='primary' onClick={activatePayroll}>
        Activate
      </Button>
    </Stack>
  );
}

interface PayrollDialogProps {
  organization: Organization | null;
  open: boolean;
  onClose: () => void;
}

function PayrollDialog({ open, onClose, organization }: PayrollDialogProps) {
  const { payrollConfig } = useAdminData(organization);

  useEffect(() => {
    if (!open) {
      return;
    }

    const initializeNmbr = async () => {
      const uniqueKey = Date.now();

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const nmbr = await (eval(`import('https://static.nmbr.co/components/*/host.js?${uniqueKey}')`) as Promise<any>);
      const companyId = payrollConfig!.nmbrCompanyId;
      const partnerId = appEnv === 'app' ? '01j5tfg6e1c1vsat4zav6jwfqb' : '01hwtrcxbc836ysjktbqyn2e9g';

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const nmbrComponent = nmbr.default.initialize({
        companyId,
        partnerId,
        signingUrl: `${axios.defaults.baseURL!}/admin/sign-nmbr-request`,
        sandbox: appEnv === 'app' ? false : true,
      });

      const container = document.querySelector('#nmbr-container');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      const frame = nmbrComponent.load('run', container, {
        hideUntilReady: true,
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      frame.ready.catch((e: Error) => {
        throw e;
      });
    };

    if (payrollConfig) {
      initializeNmbr().catch((e) => {
        throw e;
      });
    }

    return () => {
      const container = document.querySelector('#nmbr-container');
      if (container) {
        while (container.firstChild) {
          container.removeChild(container.firstChild);
        }
      }
    };
  }, [payrollConfig, open]);

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between'>
          <span>Payroll Config</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent style={{ display: 'flex' }}>
        <div id='nmbr-container' style={{ flex: 1 }}></div>
      </DialogContent>
    </Dialog>
  );
}

export function AdminPayrollPage({ ...props }) {
  const theme = useTheme();
  const { selectedOrganization, setSelectedOrganization } = useSelectedOrganization(null);
  const { organizations } = useAdminData(selectedOrganization);

  const [payrollDialogOpen, setPayrollDialogOpen] = useState(false);

  if (!organizations) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Payroll' />
        <PageBody gutter='thin'>
          <Stack alignItems='center' height='100%'>
            <CircularProgress />
          </Stack>
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <PageContainer {...props}>
      <PageHeader title='Admin - Payroll' />
      <PageBody gutter='thin'>
        <Stack height='100%'>
          <Stack direction='row' paddingTop={theme.spacing(2)}>
            <AdminOrganizationSelect
              organizations={organizations}
              onOrganizationChange={(org) => {
                setSelectedOrganization(org);
              }}
            />
          </Stack>

          {selectedOrganization?.hasPayroll ? (
            <>
              <Button variant='contained' color='primary' onClick={() => setPayrollDialogOpen(true)}>
                Open Payroll
              </Button>
              <PayrollDialog open={payrollDialogOpen} onClose={() => setPayrollDialogOpen(false)} organization={selectedOrganization} />
            </>
          ) : (
            <ActivatePayroll selectedOrganization={selectedOrganization} />
          )}
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
