import { alpha, Chip, Collapse, Fade, Link, Skeleton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';
import { CSSProperties, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { Account, deserializeTransaction, SerializedTx, Transaction, useLedger, useSession } from '../../../api';
import { useCurrentConversation } from '../../../pages/user/current-conversation-context';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../../../theme';
import { getFiscalYear } from '../../../utils/date-utils';
import { OutlineContainer, OutlineContainerSection, useOutlineContainerBorder } from '../../outline-container';
import { IWidgetProps } from '../IWidgetProps';
import { AccountDetailsDialog, restrictLength } from './clarification';

const useData = () => {
  const { data: conversationData } = useCurrentConversation();
  const { fetchAccounts, fetchJournals, journals, accounts } = useLedger();
  const { organization } = useSession();

  useEffect(() => {
    fetchJournals().catch((e) => {
      throw e;
    });
  }, [fetchJournals]);

  const formattedData = useMemo<null | {
    transactionsRequiringDocuments: Transaction[];
    transactionsAwaitingDocumentEmail: string[];
  }>(() => {
    if (conversationData === null) {
      return null;
    }

    const castedData = conversationData as {
      transactionsRequiringDocuments: SerializedTx[];
      transactionsAwaitingDocumentEmail: string[];
    };

    return {
      ...castedData,
      transactionsRequiringDocuments: castedData.transactionsRequiringDocuments.map(deserializeTransaction),
    };
  }, [conversationData]);

  useEffect(() => {
    if (!formattedData || !journals || !organization) {
      return;
    }

    const fys = new Set<string>();
    for (const tx of formattedData.transactionsRequiringDocuments) {
      fys.add(getFiscalYear(tx.date, organization.fyEndMonth));
    }

    for (const fy of fys) {
      const journal = journals.find((j) => j.fy === fy);
      if (!journal) {
        throw new Error(`No journal for fy ${fy}`);
      }

      fetchAccounts(journal.id).catch((e) => {
        throw e;
      });
    }
  }, [journals, formattedData, organization, fetchAccounts]);

  return useMemo(() => {
    if (!formattedData || !organization || !journals || !accounts) {
      return null;
    }

    const getAccount = (transaction: Transaction) => {
      const fy = getFiscalYear(transaction.date, organization.fyEndMonth);

      const journal = journals.find((j) => j.fy === fy);
      if (!journal) {
        throw new Error(`No journal for fy ${fy}`);
      }

      const fyAccounts = accounts[journal.id];
      if (!fyAccounts) {
        return null;
      }

      const account = fyAccounts.find((a) => a.externalId === transaction.accountId);
      if (!account) {
        throw new Error(`No account for tx (account external id: ${transaction.accountId})`);
      }

      return account;
    };

    const transactionsById = formattedData.transactionsRequiringDocuments.reduce(
      (map, current) => {
        const account = getAccount(current);
        if (!account) {
          return map;
        }

        map[current.id] = {
          ...current,
          account,
        };
        return map;
      },
      {} as { [transactionId: string]: Transaction & { account: Account } }
    );

    const transactionsAwaitingEmails = [] as (Transaction & { account: Account })[];
    for (const tx of formattedData.transactionsAwaitingDocumentEmail) {
      if (!transactionsById[tx]) {
        return null;
      }

      transactionsAwaitingEmails.push(transactionsById[tx]);
    }

    return transactionsAwaitingEmails;
  }, [formattedData, accounts, journals, organization]);
};

const TransactionPlaceholder = forwardRef<HTMLDivElement>(({ style }: { style?: CSSProperties }, ref) => {
  const randomSeed = useRef(Math.random());
  const theme = useTheme();

  return (
    <OutlineContainer ref={ref} background={theme.palette.background.default} style={style}>
      <OutlineContainerSection paddingX={SMALL_HORIZONTAL_SPACING} paddingY={SMALL_VERTICAL_SPACING}>
        <Skeleton variant='rounded' width={32} height={32} />

        <Stack spacing={0} flex={1}>
          <Skeleton variant='text' width={128 + randomSeed.current * 128} />
          <Skeleton variant='text' width={128 + randomSeed.current * 256} />
        </Stack>

        <Stack spacing={0}>
          <Skeleton variant='text' width={64} />
          <Skeleton variant='text' width={64} />
        </Stack>
      </OutlineContainerSection>
    </OutlineContainer>
  );
});

interface TransactionDocumentReminderProps {
  selected: boolean;
  onSelect: () => void;
  transaction: Transaction & { account: Account };
  style?: CSSProperties;
}

const TransactionDocumentReminder = forwardRef<HTMLDivElement, TransactionDocumentReminderProps>(
  ({ selected, onSelect, transaction, style }, ref) => {
    const theme = useTheme();
    const { session } = useSession();
    const [accountDetailsDialogOpen, setAccountDetailsDialogOpen] = useState(false);

    let background: string;
    if (selected) {
      background = alpha(theme.palette.primary.main, 0.05);
    } else {
      background = theme.palette.background.default;
    }

    const isExpense = parseFloat(transaction.amount) > 0;
    const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: transaction.isoCurrencyCode });

    let accountDescription: React.ReactNode;
    if (isExpense) {
      accountDescription = (
        <Typography>
          Charged to your{' '}
          <Tooltip title={transaction.account.name}>
            <Link sx={{ cursor: 'pointer' }} onClick={() => setAccountDetailsDialogOpen(true)}>
              {restrictLength(transaction.account.name, 30)}
            </Link>
          </Tooltip>{' '}
          account
        </Typography>
      );
    } else {
      accountDescription = (
        <Typography>
          Deposited to your{' '}
          <Tooltip title={transaction.account.name}>
            <Link sx={{ cursor: 'pointer' }} onClick={() => setAccountDetailsDialogOpen(true)}>
              {restrictLength(transaction.account.name, 30)}
            </Link>
          </Tooltip>{' '}
          account
        </Typography>
      );
    }

    return (
      <OutlineContainer
        ref={ref}
        background={background}
        onClick={onSelect}
        style={{
          ...style,
        }}
      >
        <OutlineContainerSection paddingX={SMALL_HORIZONTAL_SPACING} paddingY={SMALL_VERTICAL_SPACING} alignItems='start'>
          <Stack spacing={0} alignItems='center'>
            <Typography variant='small'>{formatInTimeZone(transaction.date, session?.timeZone || 'UTC', 'MMM').toUpperCase()}</Typography>
            <Typography>{formatInTimeZone(transaction.date, session?.timeZone || 'UTC', 'dd')}</Typography>
          </Stack>

          <Stack spacing={0} flex={1}>
            <Typography variant='h4'>{transaction.name}</Typography>

            {accountDescription}

            <AccountDetailsDialog open={accountDetailsDialogOpen} onClose={() => setAccountDetailsDialogOpen(false)} account={transaction.account} />
          </Stack>

          <Stack spacing={0}>
            <Typography align='right'>{amountFormatter.format(Math.abs(parseFloat(transaction.amount)))}</Typography>
            <Chip
              size='small'
              sx={{
                fontSize: '0.666rem',
              }}
              color={isExpense ? 'error' : 'primary'}
              label={isExpense ? 'EXPENSE' : 'INCOME'}
            />
          </Stack>
        </OutlineContainerSection>
      </OutlineContainer>
    );
  }
);

export const EmailDocumentReminderWidget = (_props: IWidgetProps) => {
  const theme = useTheme();
  const outlineContainerBorder = useOutlineContainerBorder();

  const transactionsAwaitingEmails = useData();

  const [selectedTransaction, setSelectedTransaction] = useState<string | null>(null);

  let transactionContent;
  if (!transactionsAwaitingEmails) {
    transactionContent = (
      <TransitionGroup component={null}>
        <Fade timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
          <TransactionPlaceholder />
        </Fade>
        <Fade timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
          <TransactionPlaceholder />
        </Fade>
        <Fade timeout={{ enter: 250, exit: 0 }} mountOnEnter unmountOnExit>
          <TransactionPlaceholder />
        </Fade>
      </TransitionGroup>
    );
  } else {
    transactionContent = (
      <TransitionGroup component={null}>
        {transactionsAwaitingEmails.map((t) => (
          <Collapse key={t.id} timeout={250} mountOnEnter unmountOnExit>
            <TransactionDocumentReminder selected={selectedTransaction === t.id} transaction={t} onSelect={() => setSelectedTransaction(t.id)} />
          </Collapse>
        ))}
      </TransitionGroup>
    );
  }

  return (
    <Fade in={true}>
      <OutlineContainer>
        <OutlineContainerSection justifyContent='space-between' background={theme.palette.background.default} borderBottom={outlineContainerBorder}>
          <Typography variant='h3'>Transactions Expecting Emailed Documents</Typography>
        </OutlineContainerSection>

        <OutlineContainerSection direction='column' alignItems='stretch' spacing={SMALL_VERTICAL_SPACING}>
          {transactionContent}
        </OutlineContainerSection>
      </OutlineContainer>
    </Fade>
  );
};
