import { CameraResultType, Camera as DeviceCamera } from '@capacitor/camera';
import { Close, HelpOutline } from '@mui/icons-material';
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Fade,
  IconButton,
  Button as MuiButton,
  Snackbar,
  SnackbarContent,
  Stack,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Add, Camera, CloseCircle, Receipt1, Send, TickCircle } from 'iconsax-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { OrganizationStatus, isMobile, useConversation as useConversationApi, useDocument, useSession } from '../../api';
import { Button, DocumentTipsDialog, PageBody, PageContainer } from '../../components';
import { DocumentEmailAddress } from '../../components/document-email-address';

const OrCircle = styled.div`
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.border.main};
  color: ${({ theme }) => theme.palette.neutral.main};
`;

const ReceiptIcon = () => {
  const theme = useTheme();

  return (
    <Box
      borderRadius={theme.roundedCorners(2)}
      padding={theme.spacing(2)}
      bgcolor={alpha(theme.palette.accent1.main, 0.1)}
      alignItems='center'
      justifyContent='center'
      width='fit-content'
    >
      <Receipt1 variant='Bold' color={theme.palette.accent1.main} />
    </Box>
  );
};

const useConversation = () => {
  const { session, createUnregisteredUser, startUnregisteredSession } = useSession();
  const { addConversationMessage } = useConversationApi();

  const startConversation = useCallback(
    async (message: string, strategy?: string) => {
      const id = uuid();
      if (!session) {
        const user = await createUnregisteredUser();
        await startUnregisteredSession(user.id);
        await addConversationMessage({ conversationId: id, message, strategy, includeMessageInConversation: !strategy });
      } else {
        await addConversationMessage({ conversationId: id, message, strategy, includeMessageInConversation: !strategy });
      }
      return id;
    },
    [createUnregisteredUser, startUnregisteredSession, addConversationMessage, session]
  );

  return {
    startConversation,
  };
};

function CameraPreview({ imageDataUrl, onClose, onUpload }: { imageDataUrl: string; onClose: () => void; onUpload: () => void }) {
  const theme = useTheme();

  return (
    <Fade in={!!imageDataUrl} unmountOnExit>
      <Box
        style={{
          backgroundColor: '#000',
          backgroundImage: `url("${imageDataUrl}")`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          position: 'fixed',
          left: 0,
          top: 0,
          margin: 0,
          width: '100%',
          height: '100%',
          paddingTop: 'var(--safe-area-inset-top)',
          paddingBottom: 'var(--safe-area-inset-bottom)',
          zIndex: 9000,
        }}
      >
        <Stack justifyContent='space-between' height='100%' padding={theme.spacing(5)}>
          <Stack direction='row' justifyContent='end'>
            <IconButton
              onClick={onClose}
              sx={{
                backgroundColor: theme.palette.mode === 'dark' ? alpha('#fff', 0.5) : alpha('#000', 0.4),
              }}
            >
              <CloseCircle color={theme.palette.mode === 'dark' ? '#000' : '#fff'} />
            </IconButton>
          </Stack>

          <Stack direction='row' justifyContent='end'>
            <Button variant='contained' color='primary' onClick={onUpload}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <span>Send</span>
                <Send color={theme.palette.mode === 'dark' ? '#000' : '#fff'} />
              </Stack>
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Fade>
  );
}

enum UploadSpinnerStatus {
  LOADING = 'LOADING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}

function UploadSpinnerIndicator({ status, onClose }: { status: UploadSpinnerStatus | null; onClose: () => void }) {
  const theme = useTheme();
  const timeout = useRef<number | null>(null);

  useEffect(() => {
    if (status === UploadSpinnerStatus.COMPLETE) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      setTimeout(() => {
        onClose();
      }, 5000);
    }
  }, [status, onClose]);

  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={status !== null} onClose={onClose}>
      <SnackbarContent
        message={
          <Stack direction='row' alignItems='center' justifyContent='space-between'>
            {status === UploadSpinnerStatus.LOADING && (
              <>
                <CircularProgress size='1rem' />
                <span>Uploading</span>
              </>
            )}
            {status === UploadSpinnerStatus.COMPLETE && (
              <>
                <TickCircle color={theme.palette.primary.main} size='1rem' />
                <span>Complete</span>
              </>
            )}
            {status === UploadSpinnerStatus.ERROR && (
              <>
                <CloseCircle color={theme.palette.error.main} size='1rem' />
                <span>Error</span>
              </>
            )}
          </Stack>
        }
        action={[
          <IconButton key='close' aria-label='close' color='inherit' onClick={onClose}>
            <Close />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}

const useDocumentCamera = () => {
  const { uploadDocuments } = useDocument();
  const [uploadSpinnerStatus, setUploadSpinnerStatus] = useState<UploadSpinnerStatus | null>(null);
  const [imagePreview, setImagePreview] = useState<{ dataUrl: string; format: string } | null>(null);

  const takePhoto = useCallback(async () => {
    const { dataUrl, format } = await DeviceCamera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
    });

    setImagePreview({
      dataUrl: dataUrl!,
      format,
    });
  }, []);

  const uploadPhoto = useCallback(async () => {
    if (!imagePreview) {
      return;
    }

    const base64String = imagePreview.dataUrl.split(',')[1];

    if (!base64String) {
      return;
    }

    setImagePreview(null);

    try {
      setUploadSpinnerStatus(UploadSpinnerStatus.LOADING);

      await uploadDocuments([
        {
          fileName: uuid(),
          mimeType: imagePreview.format,
          base64String,
        },
      ]);

      setUploadSpinnerStatus(UploadSpinnerStatus.COMPLETE);
    } catch (e) {
      setUploadSpinnerStatus(UploadSpinnerStatus.ERROR);
    }
  }, [uploadDocuments, imagePreview]);

  return {
    uploadSpinnerStatus,
    setUploadSpinnerStatus,
    takePhoto,
    imagePreview,
    setImagePreview,
    uploadPhoto,
  };
};

function AuthenticatedHomePage() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [documentTipsDialogOpen, setDocumentTipsDialogOpen] = useState(false);
  const { startConversation } = useConversation();
  const { organization } = useSession();
  const navigate = useNavigate();

  const { takePhoto, imagePreview, setImagePreview, uploadSpinnerStatus, setUploadSpinnerStatus, uploadPhoto } = useDocumentCamera();

  const uploadReceipts = useCallback(async () => {
    const id = await startConversation('', 'RECEIPT_UPLOAD');
    navigate(`/conversations/${id}`);
  }, [startConversation, navigate]);

  const content = (
    <>
      <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={0} justifyContent='space-evenly' flex={1} width='100%'>
        <Stack padding={theme.spacing(5)} spacing={5} justifyContent='center'>
          <Chip
            label='RECOMMENDED'
            size='small'
            style={{
              background: theme.palette.primary[100],
              color: theme.palette.common.black,
              width: 110,
              fontWeight: 900,
              fontSize: '0.666rem',
            }}
          />
          <Stack direction={isSmallScreen ? 'column' : 'row'} alignItems='center'>
            <Stack spacing={theme.spacing(1)}>
              <Typography variant='h2'>Send us your</Typography>
              <Typography variant='h2'>invoices and receipts</Typography>
              <DocumentEmailAddress
                sx={{
                  width: isSmallScreen ? '10rem' : '16rem',
                }}
                style={{
                  marginTop: theme.spacing(5),
                  marginBottom: theme.spacing(5),
                }}
              />
              <Typography>and let us take care of the rest.</Typography>
            </Stack>
            <img src='/otter-letter.png' alt='Otter Email' width='140px' height='121px' />
          </Stack>
        </Stack>

        <Divider orientation={isSmallScreen ? 'horizontal' : 'vertical'} flexItem style={{ padding: 0 }}>
          <OrCircle>OR</OrCircle>
        </Divider>

        <Stack padding={theme.spacing(5)} justifyContent='center' alignItems={isSmallScreen ? 'center' : 'start'}>
          <ReceiptIcon />
          <Typography>Upload your receipts and invoices</Typography>
          {isMobile && (
            <>
              <Button
                color='primary'
                variant='outlined'
                onClick={() => takePhoto()}
                disabled={organization?.status === OrganizationStatus.ANONYMOUS || organization?.status === OrganizationStatus.REGISTERED}
              >
                <Camera style={{ marginRight: theme.spacing(2) }} />
                Take Photo
              </Button>
              {imagePreview && <CameraPreview imageDataUrl={imagePreview.dataUrl} onClose={() => setImagePreview(null)} onUpload={uploadPhoto} />}
              <UploadSpinnerIndicator status={uploadSpinnerStatus} onClose={() => setUploadSpinnerStatus(null)} />
            </>
          )}
          <Button
            color='primary'
            variant='outlined'
            onClick={() => uploadReceipts()}
            disabled={organization?.status === OrganizationStatus.ANONYMOUS || organization?.status === OrganizationStatus.REGISTERED}
          >
            <Add style={{ marginRight: theme.spacing(2) }} />
            Add file
          </Button>
        </Stack>
      </Stack>
      <Divider style={{ width: '100%' }} />
      <MuiButton
        color='secondary'
        variant='text'
        onClick={() => setDocumentTipsDialogOpen(true)}
        style={{
          color: theme.palette.neutral.main,
          width: '100%',
        }}
      >
        <HelpOutline style={{ height: '1.1rem', marginRight: theme.spacing(2) }} />
        How to send your documents to Otter
      </MuiButton>
      <DocumentTipsDialog open={documentTipsDialogOpen} onClose={() => setDocumentTipsDialogOpen(false)} />
    </>
  );

  if (isSmallScreen) {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: isSmallScreen ? 1 : 0,
          padding: 0,
          minHeight: theme.spacing(100),
        }}
      >
        {content}
      </Box>
    );
  }

  return (
    <Card
      elevation={8}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      style={{
        flex: isSmallScreen ? 1 : 0,
        padding: 0,
        minHeight: theme.spacing(100),
      }}
    >
      {content}
    </Card>
  );
}

function PublicHomePage() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      direction={isSmallScreen ? 'column' : 'row'}
      justifyContent={isSmallScreen ? 'start' : 'center'}
      flex={1}
      minHeight={isSmallScreen ? undefined : 0}
      maxHeight={isSmallScreen ? undefined : theme.spacing(96)}
    >
      <Card
        elevation={8}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        style={{
          padding: theme.spacing(5),
        }}
      >
        <Chip
          label='Bookkeeping'
          style={{
            background: theme.palette.primary[200],
            color: theme.palette.common.black,
            fontWeight: 900,
            fontSize: '0.666rem',
            width: 'fit-content',
            padding: theme.spacing(2),
          }}
        />
        <Typography variant='h2' marginTop={theme.spacing(5)} marginBottom={theme.spacing(5)}>
          Automatic, stress-free bookkeeping
        </Typography>
        <Box
          style={{
            backgroundImage: `url('/home-desk-woman.png')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            backgroundSize: 'contain',
            alignSelf: 'stretch',
            minHeight: isSmallScreen ? 200 : 0,
            flex: 1,
          }}
        />
      </Card>

      <Card
        elevation={8}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        style={{
          padding: theme.spacing(5),
        }}
      >
        <Chip
          label='Expense Categorization'
          style={{
            background: theme.palette.accent1[200],
            color: theme.palette.common.black,
            fontWeight: 900,
            fontSize: '0.666rem',
            width: 'fit-content',
            padding: theme.spacing(2),
          }}
        />
        <Typography variant='h2' marginTop={theme.spacing(5)} marginBottom={theme.spacing(5)}>
          Keep track of your receipts with ease
        </Typography>
        <Box
          style={{
            backgroundImage: `url('/home-conversation.png')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            backgroundSize: 'contain',
            alignSelf: 'stretch',
            minHeight: isSmallScreen ? 200 : 0,
            flex: 1,
          }}
        />
      </Card>

      <Card
        elevation={8}
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
        style={{
          padding: theme.spacing(5),
          marginBottom: isSmallScreen ? theme.spacing(5) : 0,
        }}
      >
        <Chip
          label='Business Insights'
          style={{
            background: theme.palette.accent2[200],
            color: theme.palette.common.black,
            fontWeight: 900,
            fontSize: '0.666rem',
            width: 'fit-content',
            padding: theme.spacing(2),
          }}
        />
        <Stack spacing={1} marginTop={theme.spacing(5)} marginBottom={theme.spacing(5)}>
          <Typography variant='small' color={theme.palette.neutral.main}>
            COMING SOON
          </Typography>
          <Typography variant='h2'>Insights into Business Performance</Typography>
        </Stack>
        <Box
          style={{
            backgroundImage: `url('/home-chart-man.png')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center bottom',
            backgroundSize: 'contain',
            alignSelf: 'stretch',
            minHeight: isSmallScreen ? 200 : 0,
            flex: 1,
          }}
        />
      </Card>
    </Stack>
  );
}

export function HomePage() {
  const { session } = useSession();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const titleRef = useRef<HTMLDivElement | null>(null);

  let gutterSize: 'none' | 'thin' | 'medium';
  if (isSmallScreen && session) {
    gutterSize = 'none';
  } else if (isSmallScreen) {
    gutterSize = 'thin';
  } else {
    gutterSize = 'medium';
  }

  return (
    <PageContainer>
      <PageBody gutter={gutterSize}>
        <Stack
          height='100%'
          justifyContent={isSmallScreen ? 'start' : 'center'}
          alignItems={isSmallScreen && !session ? 'center' : 'stretch'}
          style={{
            minHeight: 0,
            flex: 1,
          }}
        >
          {!isSmallScreen && !session && (
            <Stack alignItems='center' spacing={2} ref={titleRef}>
              <Typography variant='h1' style={{ fontFamily: 'Larken' }}>
                We've got you covered
              </Typography>
              <Typography variant='h4'>Sign up or sign in to get started</Typography>
            </Stack>
          )}
          {session ? <AuthenticatedHomePage /> : <PublicHomePage />}
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
