import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Copy, CopySuccess, Eye } from 'iconsax-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Account, AnnualCalculations, Document, Journal, JournalEntry, MonthlyCalculations, Organization, Transaction, useAdmin } from '../../../api';
import { AdminJournalSelect, AdminOrganizationSelect, PageBody, PageContainer, PageHeader, useSelectedOrganization } from '../../../components';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../../../theme';
import { zonedTimeToUtc } from '../../../utils/date-utils';
import { useFormState } from '../../../utils/useFormState';

const SimpleTable = styled.table`
  border-collapse: separate;
  border-spacing: 0px;

  > thead {
    > tr {
      > th {
        padding-top: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
        padding-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
        padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
        padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};

        border-right: 1px solid ${({ theme }) => theme.palette.border.main};
        border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
      }

      > th:first-child,
      th:first-child {
        border-left: 1px solid ${({ theme }) => theme.palette.border.main};
      }
    }

    > tr:first-child {
      > th {
        border-top: 1px solid ${({ theme }) => theme.palette.border.main};
      }

      > :first-child {
        border-top-left-radius: ${({ theme }) => theme.roundedCorners(5)};
      }

      > :last-child {
        border-top: 1px solid ${({ theme }) => theme.palette.border.main};
        border-top-right-radius: ${({ theme }) => theme.roundedCorners(5)};
      }
    }

    th {
      font-weight: 500;
    }
  }

  > tbody {
    > tr {
      > td {
        padding-top: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
        padding-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
        padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
        padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};

        border-right: 1px solid ${({ theme }) => theme.palette.border.main};
        border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
      }

      > td:first-child,
      th:first-child {
        border-left: 1px solid ${({ theme }) => theme.palette.border.main};
      }
    }

    > :last-child {
      > :first-child {
        border-left: 1px solid ${({ theme }) => theme.palette.border.main};
        border-bottom-left-radius: ${({ theme }) => theme.roundedCorners(5)};
      }

      > :last-child {
        border-bottom-right-radius: ${({ theme }) => theme.roundedCorners(5)};
      }
    }
  }
`;

enum Period {
  MONTHLY = 'Monthly',
  ANNUAL = 'Annual',
}

const monthMap: Record<string, number> = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

const useAdminData = (selectedOrg: Organization | null, selectedJournal: Journal | null, selectedMonth: string | null, period: Period) => {
  const { fetchOrganizations, fetchJournals, fetchJournalAccounts, journals, journalAccounts, organizations, fetchCalculations, calculations } =
    useAdmin();
  const year = selectedJournal?.fy;
  const month = period === Period.MONTHLY ? selectedMonth : null;

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });
  }, [fetchOrganizations]);

  useEffect(() => {
    if (!selectedOrg) {
      return;
    }

    fetchJournals(selectedOrg.id!).catch((e) => {
      throw e;
    });
  }, [fetchJournals, selectedOrg]);

  useEffect(() => {
    if (!selectedOrg || !year) {
      return;
    }

    fetchCalculations(selectedOrg.id!, year, month ? monthMap[month] : null).catch((e) => {
      throw e;
    });
  }, [fetchCalculations, selectedOrg, month, year]);

  const orgJournals = useMemo(() => {
    if (!selectedOrg) {
      return [];
    }

    return journals[selectedOrg.id!] || [];
  }, [selectedOrg, journals]);

  useEffect(() => {
    if (!orgJournals.length) {
      return;
    }

    const accountPromises = [];
    for (const journal of orgJournals) {
      accountPromises.push(fetchJournalAccounts(journal.id));
    }

    Promise.all(accountPromises).catch((e) => {
      throw e;
    });
  }, [orgJournals, fetchJournalAccounts]);

  const selectedJournalAccounts = useMemo(() => {
    if (!journalAccounts || !selectedJournal) {
      return null;
    }

    return journalAccounts[selectedJournal.id] || [];
  }, [journalAccounts, selectedJournal]);

  const selectedPeriodCalculations = useMemo<AnnualCalculations | MonthlyCalculations | null>(() => {
    if (year && selectedOrg?.id && calculations[selectedOrg.id]) {
      if (month) {
        return calculations[selectedOrg.id].monthly[`${year}-${monthMap[month]}`] || null;
      } else {
        return calculations[selectedOrg.id].annual[year] || null;
      }
    } else {
      return null;
    }
  }, [selectedOrg, calculations, year, month]);

  return {
    organizations,
    journals: orgJournals,
    accounts: selectedJournalAccounts,
    calculations: selectedPeriodCalculations,
  };
};

function Insight({ label, value }: { label: string; value: string | null }) {
  const theme = useTheme();

  return (
    <Box>
      <Typography>{label}</Typography>
      {value ? <Typography>{value}</Typography> : <Skeleton width={theme.spacing(24)} />}
    </Box>
  );
}

function MonthDropdown({
  selectedMonth,
  setSelectedMonth,
  months,
}: {
  selectedMonth: string;
  setSelectedMonth: (month: string) => void;
  months: string[];
}) {
  return (
    <FormControl style={{ minWidth: 200 }} size='small'>
      <InputLabel id='month-select-label'>Month</InputLabel>
      <Select
        label='Month'
        labelId='month-select-label'
        autoWidth
        value={selectedMonth}
        onChange={(event) => {
          setSelectedMonth(event.target.value);
        }}
      >
        {months.map((month) => (
          <MenuItem key={month} value={month} style={{ minWidth: 200 }}>
            {month}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function currencyFormatter(currency: string, formatterCache: { [currency: string]: Intl.NumberFormat }) {
  if (!formatterCache[currency]) {
    formatterCache[currency] = new Intl.NumberFormat('en-CA', { style: 'currency', currency });
  }

  return formatterCache[currency];
}

function ExtremeTable({
  loading,
  journal,
  info,
  mode,
}: {
  loading: boolean;
  journal: Journal | null;
  info: null | {
    transaction?: Transaction;
    document?: Document;
    journalEntry?: JournalEntry;
    amount: string;
    percentageOfTotal: number;
  };
  mode: 'expenses' | 'sales';
}) {
  const theme = useTheme();

  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (documentId: string) => {
    setIdCopied(documentId);
    await navigator.clipboard.writeText(documentId);
  }, []);

  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal?.currency || 'CAD' });
  const percentFormatter = new Intl.NumberFormat('en-CA', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const currencyFormatters = {} as { [currency: string]: Intl.NumberFormat };

  let date: string | null = null;
  let name: string | null = null;
  let amount: string | null = null;
  let percentage: string | null = null;
  let idLabel: string | null = null;
  let id: string | null = null;
  let link: string | null = null;
  if (!loading && journal) {
    date = 'N/A';
    name = 'N/A';
    amount = 'None';
    percentage = 'N/A';
    idLabel = 'N/A';
    id = 'N/A';

    if (info?.transaction) {
      date = format(zonedTimeToUtc(info.transaction.date), 'MMM d, yyyy');

      name = info.transaction.name;

      amount = `${amountFormatter.format(parseFloat(info.amount))}${
        info.transaction.isoCurrencyCode !== journal.currency
          ? ` (${currencyFormatter(info.transaction.isoCurrencyCode, currencyFormatters).format(Math.abs(parseFloat(info.transaction.amount)))})`
          : ''
      }`;

      percentage = percentFormatter.format(info.percentageOfTotal);

      idLabel = 'TID';

      id = info.transaction.id;

      link = `/admin/transactions?tab=ALL&transactionIds=${id}`;
    } else if (info?.document) {
      date = format(zonedTimeToUtc(info.document.date!), 'MMM d, yyyy');

      name = info.document.merchantName;

      amount = `${amountFormatter.format(parseFloat(info.amount))}${
        info.document.currency !== journal.currency
          ? ` (${currencyFormatter(info.document.currency, currencyFormatters).format(Math.abs(parseFloat(info.document.afterTax!)))})`
          : ''
      }`;

      percentage = percentFormatter.format(info.percentageOfTotal);

      idLabel = 'DID';

      id = info.document.id;

      link = `/admin/documents?tab=ALL&documentIds=${id}`;
    } else if (info?.journalEntry) {
      date = format(zonedTimeToUtc(info.journalEntry.date), 'MMM d, yyyy');

      name = info.journalEntry.memo;

      amount = amountFormatter.format(parseFloat(info.amount));

      percentage = percentFormatter.format(info.percentageOfTotal);

      idLabel = 'JEID';

      id = info.journalEntry.id;

      link = `/admin/journals?journalEntryIds=${id}`;
    }
  }

  return (
    <SimpleTable>
      <thead>
        <tr>
          <th>{idLabel}</th>
          <th>Date (UTC)</th>
          <th>Name</th>
          <th>Amount</th>
          <th>Percentage of Overall {mode === 'expenses' ? 'Expenses' : 'Sales'}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {loading && (
          <tr>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>
        )}
        {!loading && (
          <tr>
            <td align='center'>
              <Tooltip title={id}>
                <span>
                  <IconButton onClick={() => copy(id!)}>
                    {idCopied === id ? (
                      <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                    ) : (
                      <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            </td>

            <td>{date}</td>

            <td>{name}</td>

            <td>{amount}</td>

            <td>{percentage}</td>

            <td align='center'>
              <Tooltip title='View'>
                <span>
                  <IconButton
                    onClick={() => {
                      window.open(link!, '_blank');
                    }}
                  >
                    <Eye size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                  </IconButton>
                </span>
              </Tooltip>
            </td>
          </tr>
        )}
      </tbody>
    </SimpleTable>
  );
}

function TargetCalculator({ loading, totalSales }: { loading: boolean; totalSales: string | null }) {
  const [annualTarget, setAnnualTarget, annualTargetTouched, setAnnualTargetTouched] = useFormState('');

  const annualTargetValid = useMemo(() => {
    return !isNaN(parseFloat(annualTarget));
  }, [annualTarget]);

  const targetProgress = useMemo(() => {
    if (!totalSales || !annualTargetValid) {
      return null;
    }

    return parseFloat(totalSales) / parseFloat(annualTarget);
  }, [annualTarget, annualTargetValid, totalSales]);

  const percentFormatter = new Intl.NumberFormat('en-CA', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return (
    <Stack direction='row'>
      <FormControl error={annualTargetTouched && !annualTargetValid}>
        <TextField
          label='Annual Target'
          placeholder='Annual Target...'
          value={annualTarget}
          disabled={loading}
          onBlur={() => setAnnualTargetTouched(true)}
          onChange={(e) => setAnnualTarget(e.target.value)}
        />
        <FormHelperText>{annualTargetTouched && !annualTargetValid && 'Invalid value'}</FormHelperText>
      </FormControl>

      <TextField
        label='Monthly Target Progress'
        value={targetProgress !== null ? percentFormatter.format(targetProgress) : '-'}
        disabled={loading}
        InputProps={{ readOnly: true, disableUnderline: true }}
        onChange={(e) => setAnnualTarget(e.target.value)}
      />
    </Stack>
  );
}

function MonthlyCalculationsView({
  calculations,
  accounts,
  selectedJournal,
}: {
  calculations: MonthlyCalculations | null;
  accounts: Account[] | null;
  selectedJournal: Journal | null;
}) {
  const theme = useTheme();
  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: selectedJournal?.currency || 'CAD' });

  const accountsById = useMemo(() => {
    if (!accounts) {
      return null;
    }

    return accounts.reduce(
      (map, current) => {
        map[current.id] = current;
        return map;
      },
      {} as { [accountId: string]: Account }
    );
  }, [accounts]);

  return (
    <Stack paddingBottom={theme.spacing(10)}>
      <Typography variant='h4'>Sales</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Insight label='Total Sales' value={calculations ? amountFormatter.format(parseFloat(calculations.totalSales)) : null} />

        <Typography variant='h5'>Sales Target</Typography>
        <TargetCalculator loading={!calculations} totalSales={calculations?.totalSales || null} />

        <Typography variant='h5'>Largest Sale</Typography>
        <ExtremeTable loading={!calculations} journal={selectedJournal} info={calculations?.largestSale || null} mode='sales' />

        <Typography variant='h5'>Smallest Sale</Typography>
        <ExtremeTable loading={!calculations} journal={selectedJournal} info={calculations?.smallestSale || null} mode='sales' />
      </Stack>

      <Typography variant='h4'>Expenses</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Insight label='Total Expenses' value={calculations ? amountFormatter.format(parseFloat(calculations.totalExpenses)) : null} />

        <Typography variant='h5'>Largest Expense</Typography>
        <ExtremeTable loading={!calculations} journal={selectedJournal} info={calculations?.largestExpense || null} mode='expenses' />

        <Typography variant='h5'>Smallest Expense</Typography>
        <ExtremeTable loading={!calculations} journal={selectedJournal} info={calculations?.smallestExpense || null} mode='expenses' />

        <Typography variant='h5'>Expense Breakdown</Typography>
        <SimpleTable>
          <thead>
            <tr>
              <th>Account</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {(!calculations || !accountsById) && (
              <tr>
                <td>
                  <Skeleton />
                </td>
                <td>
                  <Skeleton />
                </td>
              </tr>
            )}
            {calculations && !Object.keys(calculations.expenseTotals).length && (
              <tr>
                <td align='center' colSpan={2}>
                  No expenses
                </td>
              </tr>
            )}
            {calculations &&
              accountsById &&
              Object.entries(calculations.expenseTotals).map(([accountId, total]) => (
                <tr key={accountId}>
                  <td>{accountsById[accountId].name}</td>
                  <td>{amountFormatter.format(parseFloat(total))}</td>
                </tr>
              ))}
          </tbody>
        </SimpleTable>
      </Stack>

      <Typography variant='h4'>Income</Typography>
      <Stack paddingLeft={theme.spacing(5)}>
        <Insight label='Net Income' value={calculations ? amountFormatter.format(parseFloat(calculations.netIncome)) : null} />
      </Stack>
    </Stack>
  );
}

function ArApBreakdownTable({
  documents,
  documentAges,
}: {
  documents: Document[];
  documentAges: {
    [documentId: string]: number | null;
  };
}) {
  const theme = useTheme();
  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (documentId: string) => {
    setIdCopied(documentId);
    await navigator.clipboard.writeText(documentId);
  }, []);

  const viewDocument = useCallback((documentId: string) => {
    window.open(`/admin/documents?tab=ALL&documentIds=${documentId}`, '_blank');
  }, []);

  return (
    <SimpleTable>
      <thead>
        <tr>
          <th>ID</th>
          <th>Date</th>
          <th>Name</th>
          <th>Amount</th>
          <th>Age</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {documents.map((d) => {
          const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: d.currency || 'CAD' });

          return (
            <tr key={d.id}>
              <td>
                <Tooltip title={d.id}>
                  <span>
                    <IconButton onClick={() => copy(d.id)}>
                      {idCopied === d.id ? (
                        <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                      ) : (
                        <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </td>

              <td>{d.date ? formatInTimeZone(d.date, 'utc', 'MMM d, yyyy') : 'Unknown'}</td>

              <td>{d.merchantName || 'Unknown'}</td>

              <td>{d.afterTax ? amountFormatter.format(parseFloat(d.afterTax)) : 'Unknown'}</td>

              <td>{documentAges[d.id] === null ? 'Paid in next FY' : `${String(documentAges[d.id])} days`}</td>

              <td>
                <Tooltip title='View Document'>
                  <span>
                    <IconButton onClick={() => viewDocument(d.id)}>
                      <Eye size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                    </IconButton>
                  </span>
                </Tooltip>
              </td>
            </tr>
          );
        })}
      </tbody>
    </SimpleTable>
  );
}

function AnnualCalculationsView({ calculations, selectedJournal }: { calculations: AnnualCalculations | null; selectedJournal: Journal | null }) {
  const theme = useTheme();
  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: selectedJournal?.currency || 'CAD' });
  const percentFormatter = new Intl.NumberFormat('en-CA', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const currencyFormatters = {} as { [currency: string]: Intl.NumberFormat };

  const salesTotalsByMonth = useMemo(() => {
    if (!calculations?.salesTotalsByMonth || !calculations.salesGrowthByMonth) {
      return null;
    }

    const byMonth = {} as {
      [month: number]: {
        total: string;
        growth: string;
      };
    };
    for (const [month, monthlyTotal] of Object.entries(calculations.salesTotalsByMonth)) {
      if (!byMonth[Number(month)]) {
        byMonth[Number(month)] = {
          total: '0',
          growth: '0',
        };
      }

      byMonth[Number(month)].total = monthlyTotal;
    }
    for (const [month, percent] of Object.entries(calculations.salesGrowthByMonth)) {
      byMonth[Number(month)].growth = percent;
    }

    return byMonth;
  }, [calculations]);

  const salesTotalsByCurrency = useMemo(() => {
    if (!calculations?.salesTotalsByCurrency) {
      return null;
    }

    const byCurrency = {} as {
      [currency: string]: {
        original: string;
        converted: string;
      };
    };
    for (const [currency, currencySalesTotal] of Object.entries(calculations.salesTotalsByCurrency)) {
      if (!byCurrency[currency]) {
        byCurrency[currency] = {
          original: '0',
          converted: '0',
        };
      }

      byCurrency[currency] = {
        original: currencySalesTotal.original,
        converted: currencySalesTotal.converted,
      };
    }

    return byCurrency;
  }, [calculations]);

  return (
    <Stack>
      <Typography variant='h4'>A/P & A/R Breakdown</Typography>

      <Stack paddingLeft={theme.spacing(5)}>
        <Stack>
          <Typography variant='h5'>A/P</Typography>

          <Stack direction='row' alignItems='center'>
            <Insight label='Total' value={calculations ? amountFormatter.format(parseFloat(calculations.accountsPayable.total)) : null} />

            <Divider orientation='vertical' flexItem={true} />

            {selectedJournal && calculations ? (
              calculations.accountsPayable.documents.length ? (
                <ArApBreakdownTable documents={calculations.accountsPayable.documents} documentAges={calculations.accountsPayable.documentAges} />
              ) : (
                <Typography>All Paid</Typography>
              )
            ) : (
              <Skeleton />
            )}
          </Stack>
        </Stack>

        <Stack>
          <Typography variant='h5'>A/R</Typography>

          <Stack direction='row' alignItems='center'>
            <Insight label='Total' value={calculations ? amountFormatter.format(parseFloat(calculations.accountsReceivable.total)) : null} />

            <Divider orientation='vertical' flexItem={true} />

            {selectedJournal && calculations ? (
              calculations.accountsReceivable.documents.length ? (
                <ArApBreakdownTable
                  documents={calculations.accountsReceivable.documents}
                  documentAges={calculations.accountsReceivable.documentAges}
                />
              ) : (
                <Typography>All Paid</Typography>
              )
            ) : (
              <Skeleton />
            )}
          </Stack>
        </Stack>
      </Stack>

      <Typography variant='h4'>Sales</Typography>

      <Stack paddingLeft={theme.spacing(5)}>
        <Insight label='Total Sales' value={calculations ? amountFormatter.format(parseFloat(calculations.totalSales)) : null} />

        <Typography variant='h5'>Sales Target</Typography>
        <TargetCalculator loading={!calculations} totalSales={calculations?.totalSales || null} />

        <Stack>
          <Typography variant='h5'>Sales by Month</Typography>
          {salesTotalsByMonth ? (
            <SimpleTable>
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Sales</th>
                  <th>Growth</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(salesTotalsByMonth).map(([month, totalSales]) => (
                  <tr key={`sales-by-month-${month}`}>
                    <td>{Object.keys(monthMap)[Number(month) - 1]}</td>
                    <td>{amountFormatter.format(parseFloat(totalSales.total))}</td>
                    <td>{totalSales.growth === 'Infinity' ? 'Infinity' : percentFormatter.format(parseFloat(totalSales.growth))}</td>
                  </tr>
                ))}
              </tbody>
            </SimpleTable>
          ) : (
            <Skeleton />
          )}
        </Stack>

        <Stack>
          <Typography variant='h5'>Sales by Currency</Typography>
          {salesTotalsByCurrency ? (
            <SimpleTable>
              <thead>
                <tr>
                  <th>Currency</th>
                  <th>Original</th>
                  <th>Converted</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(salesTotalsByCurrency).map(([currency, totalSales]) => (
                  <tr key={`sales-by-month-${currency}`}>
                    <td>{currency}</td>
                    <td>{currencyFormatter(currency, currencyFormatters).format(parseFloat(totalSales.original))}</td>
                    <td>{amountFormatter.format(parseFloat(totalSales.converted))}</td>
                  </tr>
                ))}
              </tbody>
            </SimpleTable>
          ) : (
            <Skeleton />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export function AdminCalculationsPage({ ...props }) {
  const theme = useTheme();

  const { selectedOrganization: selectedOrg, setSelectedOrganization: setSelectedOrg, previousSelectedOrganization } = useSelectedOrganization(null);
  const [selectedJournal, setSelectedJournal] = useState<Journal | null>(null);

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const currentMonth = months[new Date().getMonth()];
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const [period, setperiod] = useState(Period.ANNUAL);

  const { organizations, journals, accounts, calculations } = useAdminData(selectedOrg, selectedJournal, selectedMonth, period);

  if (!organizations) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Calculations' />
        <PageBody gutter='thin'>
          <Stack alignItems='center' height='100%'>
            <CircularProgress />
          </Stack>
        </PageBody>
      </PageContainer>
    );
  }

  let calculationsView;
  if (period === Period.MONTHLY) {
    calculationsView = (
      <MonthlyCalculationsView selectedJournal={selectedJournal} accounts={accounts} calculations={calculations as MonthlyCalculations | null} />
    );
  } else {
    calculationsView = <AnnualCalculationsView selectedJournal={selectedJournal} calculations={calculations as AnnualCalculations | null} />;
  }

  return (
    <PageContainer {...props}>
      <PageHeader title='Admin - Calculations' />
      <PageBody gutter='thin'>
        <Stack height='100%'>
          <Stack direction='row' paddingTop={theme.spacing(2)}>
            <AdminOrganizationSelect
              organizations={organizations}
              onOrganizationChange={(org) => {
                setSelectedOrg(org);
                if (selectedOrg?.id !== previousSelectedOrganization?.id) {
                  setSelectedJournal(null);
                }
              }}
            />
            <AdminJournalSelect
              journals={journals}
              organization={selectedOrg}
              selectedJournal={selectedJournal}
              onJournalChange={(j) => setSelectedJournal(j)}
            />
          </Stack>
          <Tabs
            value={period}
            onChange={(_e, newValue: Period) => {
              setperiod(newValue);
            }}
          >
            <Tab label='Annual' value={Period.ANNUAL} />
            <Tab label='Monthly' value={Period.MONTHLY} />
          </Tabs>
          <Stack direction='row'>
            {period === Period.MONTHLY && <MonthDropdown months={months} selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} />}
          </Stack>

          {calculationsView}
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
