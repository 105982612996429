import { createContext, useCallback, useContext, useState } from 'react';
import { axios } from './axios-base';
import { convertFilesToFileDetails } from './document';

export enum InstitutionStatus {
  CONNECTED = 'CONNECTED',
  CONNECTION_STALE = 'CONNECTION_STALE',
  CONNECTION_FAILED = 'CONNECTION_FAILED',
}

export interface Institution {
  id: string;
  name: string;
  status: InstitutionStatus;
}

interface TransactionClarificationData {
  transactionId: string;
  description?: string;
  noDocuments?: boolean;
  documentFiles?: File[];
  awaitingDocumentEmail?: boolean;
}

interface ITransactionContext {
  institutions: Institution[] | null;
  authenticate: (publicToken: string) => Promise<void>;
  fetchInstitutions: () => Promise<void>;
  createLinkToken: () => Promise<string>;
  setLinkItemLoginRequired: (institutionId: string) => Promise<void>;
  createUpdateModeLinkToken: (institutionId: string) => Promise<string>;
  uploadCsv: (accountNumber: string | undefined, csv: string, flipAmounts: boolean) => Promise<void>;
  uploadStatement: (statementFile: File, statementType: string, accountId: string) => Promise<void>;
  recordPlaidLinkSessionFailure: (linkSessionId: string) => Promise<void>;
  removeInstitution: (institutionId: string) => Promise<void>;
  clarify: (clarificationData: TransactionClarificationData) => Promise<void>;
}

function useTransactionData() {
  const [institutions, setInstitutions] = useState<Institution[] | null>(null);

  const fetchInstitutions = useCallback(async () => {
    const response = await axios.get('/institutions');
    const institutionData = response.data as { institutions: Institution[] };

    setInstitutions(institutionData.institutions);
  }, []);

  const createLinkToken = useCallback(async () => {
    const response = await axios.post('/plaid-link-token');
    const tokenData = response.data as { token: string };
    return tokenData.token;
  }, []);

  const setLinkItemLoginRequired = useCallback(async (institutionId: string) => {
    await axios.post('/plaid-set-item-login-required', {
      institutionId,
    });
  }, []);

  const createUpdateModeLinkToken = useCallback(async (institutionId: string) => {
    const response = await axios.post('/plaid-update-mode-link-token', {
      institutionId,
    });
    const tokenData = response.data as { token: string };
    return tokenData.token;
  }, []);

  const authenticate = useCallback(
    async (publicToken: string) => {
      await axios.post('/plaid-authenticate', {
        publicToken,
      });

      await fetchInstitutions();
    },
    [fetchInstitutions]
  );

  const uploadCsv = useCallback(async (accountNumber: string | undefined, csv: string, flipAmounts: boolean) => {
    await axios.post('/csv', {
      accountNumber,
      data: csv,
      flipAmounts,
    });
  }, []);

  const uploadStatement = useCallback(async (statementFile: File, statementType: string, accountId: string) => {
    const formData = new FormData();
    formData.append('file', statementFile);
    formData.append('statementType', statementType);
    formData.append('accountExternalId', accountId);

    await axios.post('/statement-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }, []);

  const recordPlaidLinkSessionFailure = useCallback(async (linkSessionId: string) => {
    await axios.post('/plaid-link-session-failure', {
      linkSessionId,
    });
  }, []);

  const removeInstitution = useCallback(
    async (id: string) => {
      await axios.delete(`/institutions/${id}`);
      await fetchInstitutions();
    },
    [fetchInstitutions]
  );

  const clarify = useCallback(
    async ({ transactionId, description, documentFiles, noDocuments, awaitingDocumentEmail }: TransactionClarificationData) => {
      const fileDetails = documentFiles ? await convertFilesToFileDetails(documentFiles) : undefined;

      await axios.post(`/transactions/${transactionId}/clarify`, {
        description,
        documents: fileDetails,
        noDocuments,
        awaitingDocumentEmail,
      });
    },
    []
  );

  const transactionContext: ITransactionContext = {
    institutions,
    authenticate,
    fetchInstitutions,
    setLinkItemLoginRequired,
    createLinkToken,
    createUpdateModeLinkToken,
    uploadCsv,
    uploadStatement,
    recordPlaidLinkSessionFailure,
    removeInstitution,
    clarify,
  };
  return transactionContext;
}

export const TransactionContext = createContext<ITransactionContext>({} as ITransactionContext);

export const useTransaction = () => useContext(TransactionContext);

export const TransactionContextProvider = ({ children }: { children: React.ReactNode }) => {
  const transaction = useTransactionData();

  return <TransactionContext.Provider value={transaction}>{children}</TransactionContext.Provider>;
};
