import { Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import { CloseCircle } from 'iconsax-react';
import { Account } from '../../../api';
import { ThreeColumn } from '../../three-column';

export function restrictLength(str: string, length: number, delimiter = ' ') {
  const tokenized = str.split(delimiter);

  let sum = 0;
  const approvedFrontParts = [] as string[];
  const approvedBackParts = [] as string[];

  if (tokenized.length === 1) {
    return str;
  }

  for (let i = 0; i < Math.ceil(tokenized.length / 2); i++) {
    if (i !== tokenized.length - 1 - i) {
      const frontPart = tokenized[i];
      if (sum + frontPart.length + 1 < length) {
        sum += frontPart.length;
        approvedFrontParts.push(frontPart);
      }
    }

    const backPart = tokenized[tokenized.length - 1 - i];
    if (sum + backPart.length + 1 < length) {
      sum += backPart.length;
      approvedBackParts.push(backPart);
    }
  }

  const frontJoined = approvedFrontParts.join(delimiter);
  const backJoined = approvedBackParts.join(delimiter);

  if (frontJoined + delimiter + backJoined === str) {
    return str;
  }

  return frontJoined + ' ... ' + backJoined;
}

export function AccountDetailsDialog({ open, onClose, account }: { open: boolean; onClose: () => void; account: Account | null }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <DialogTitle>
        <ThreeColumn align='center'>
          <span></span>
          <span>Account Details</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent>
        {account && (
          <Stack>
            <TextField InputProps={{ readOnly: true, disableUnderline: true }} fullWidth label='Account Name' value={account.name} />

            <TextField InputProps={{ readOnly: true, disableUnderline: true }} fullWidth label='Account Type' value={account.standardAccount} />

            {account.name !== account.description && (
              <TextField InputProps={{ readOnly: true, disableUnderline: true }} fullWidth label='Account Description' value={account.description} />
            )}

            {account.externalMaskedAccountId && (
              <TextField
                InputProps={{ readOnly: true, disableUnderline: true }}
                fullWidth
                label='Account Number'
                value={`****${account.externalMaskedAccountId}`}
              />
            )}

            {account.externalCurrency && (
              <TextField
                InputProps={{ readOnly: true, disableUnderline: true }}
                fullWidth
                label='Account Currency'
                value={account.externalCurrency}
              />
            )}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}
