import { alpha, Box, Typography, useTheme } from '@mui/material';
import { endOfMonth, format, getDaysInMonth, isSameDay } from 'date-fns';
import { CSSProperties, forwardRef } from 'react';
import styled from 'styled-components';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../theme';
import { ScreenSize, useScreenSize } from '../utils/use-screen-size';

const CalendarBlank = () => {
  return <CalendarTile></CalendarTile>;
};

const CalendarHeader = styled(({ label, ...props }: { label: string }) => {
  return (
    <CalendarTile {...props}>
      <Typography variant='h5'>{label}</Typography>
    </CalendarTile>
  );
})`
  &&:hover {
    cursor: auto;
    background: none;
  }
`;

const CalendarDay = ({
  year,
  month,
  day,
  viewingMonth,
  selected,
  onClick,
  children,
}: {
  year: number;
  month: number;
  day: number;
  viewingMonth: number;
  selected: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}) => {
  const theme = useTheme();

  const today = new Date();
  const date = new Date(year, month - 1, day);
  const label = day === 1 ? format(date, 'MMM d') : day;

  const isToday = isSameDay(today, date);

  let labelColor: string | undefined;
  if (isToday) {
    labelColor = theme.palette.primary.contrastText;
  } else if (month !== viewingMonth) {
    labelColor = alpha(theme.palette.text.primary, 0.5);
  } else {
    labelColor = undefined;
  }

  return (
    <CalendarTile onClick={onClick} selected={selected}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <Box
          padding={theme.spacing(2)}
          style={{
            background: isToday ? theme.palette.primary.main : undefined,
            borderRadius: isToday ? '50%' : undefined,
            width: day === 1 ? undefined : '1.5rem',
            height: '1.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant='small' color={labelColor}>
            {label}
          </Typography>
        </Box>
      </Box>

      <Box>{children}</Box>
    </CalendarTile>
  );
};

const CalendarTile = styled.div<{ selected?: boolean }>`
  padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
  padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
  padding-top: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
  padding-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};

  background: ${({ theme, selected }) => {
    if (selected) {
      return alpha(theme.palette.primary.main, 0.1);
    } else {
      return 'none';
    }
  }};

  &:hover {
    cursor: pointer;
    background: ${({ theme, selected }) => {
      if (selected) {
        return alpha(theme.palette.primary.main, 0.2);
      } else {
        return alpha(theme.palette.border.main, 0.2);
      }
    }};
  }
`;

const CalendarGrid = styled.div<{ screenSize: ScreenSize; size: number }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-auto-rows: 1fr;
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ theme }) => theme.roundedCorners(5)};
  overflow: hidden;

  > * {
    border-left: ${({ theme }) => `1px solid ${theme.palette.border.main}`};
    border-bottom: ${({ theme }) => `1px solid ${theme.palette.border.main}`};
  }

  > :nth-child(-n + 7) {
    border-top: ${({ theme }) => `1px solid ${theme.palette.border.main}`};
  }

  > :nth-child(7n) {
    border-right: ${({ theme }) => `1px solid ${theme.palette.border.main}`};
  }

  > :first-child {
    border-top-left-radius: ${({ theme }) => theme.roundedCorners(5)};
  }

  > :nth-child(7) {
    border-top-right-radius: ${({ theme }) => theme.roundedCorners(5)};
  }

  > :nth-child(${({ size }) => (size / 7 - 1) * 7 + 1}) {
    border-bottom-left-radius: ${({ theme }) => theme.roundedCorners(5)};
  }

  > :last-child {
    border-bottom-right-radius: ${({ theme }) => theme.roundedCorners(5)};
  }
`;

export interface CalendarProps {
  year: number;
  month: number;
  selectedDay?: { year: number; month: number; day: number } | null;
  onDayClicked: (year: number, month: number, day: number) => void;
  dayChildren?: (year: number, month: number, day: number) => React.ReactNode;
  style?: CSSProperties;
}

export const Calendar = forwardRef<HTMLDivElement, CalendarProps>(({ year, month, selectedDay, onDayClicked, dayChildren, style }, ref) => {
  const screenSize = useScreenSize();

  const daysInMonth = getDaysInMonth(new Date(year, month - 1, 1));

  const firstDay = new Date(year, month - 1, 1);
  const offset = firstDay.getDay();

  const lastDayOfPreviousMonth = endOfMonth(new Date(year, month - 1, 1));

  const calendarTiles = [];
  for (const day of ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']) {
    calendarTiles.push(<CalendarHeader key={`label-${day}`} label={day} />);
  }

  const calendarDays = [];
  for (let i = 0; i < offset; i++) {
    const dayNumber = lastDayOfPreviousMonth.getDate() - i;
    calendarDays.unshift(
      <CalendarDay
        key={`${month}-${dayNumber}`}
        year={year}
        month={month - 1}
        day={dayNumber}
        viewingMonth={month}
        selected={selectedDay?.day === dayNumber && selectedDay?.month === month - 1 && selectedDay?.year === year}
        onClick={() => onDayClicked(year, month - 1, dayNumber)}
      >
        {dayChildren && dayChildren(year, month - 1, dayNumber)}
      </CalendarDay>
    );
  }
  for (let i = 1; i <= daysInMonth; i++) {
    calendarDays.push(
      <CalendarDay
        key={i}
        year={year}
        month={month}
        day={i}
        viewingMonth={month}
        selected={selectedDay?.day === i && selectedDay?.month === month && selectedDay?.year === year}
        onClick={() => onDayClicked(year, month, i)}
      >
        {dayChildren && dayChildren(year, month, i)}
      </CalendarDay>
    );
  }

  calendarTiles.push(...calendarDays);

  while (calendarTiles.length % 7 !== 0) {
    calendarTiles.push(<CalendarBlank />);
  }

  return (
    <CalendarGrid screenSize={screenSize} size={calendarTiles.length} ref={ref} style={style}>
      {calendarTiles}
    </CalendarGrid>
  );
});
