import { CircularProgress, Fade, Popover, Stack, Typography, useTheme } from '@mui/material';
import { StaticDateTimePicker } from '@mui/x-date-pickers';
import { addDays, format } from 'date-fns';
import { Calendar, TickCircle } from 'iconsax-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useConversation, useOrganization } from '../../../api';
import { useCurrentConversation } from '../../../pages/user/current-conversation-context';
import { ScreenSize, useScreenSize } from '../../../utils/use-screen-size';
import { Button } from '../../button';
import { ConfirmDialog } from '../../confirm-dialog';
import { OutlineContainer, OutlineContainerSection } from '../../outline-container';
import { IWidgetProps } from '../IWidgetProps';

export interface ScheduleCheckInWidgetProps extends IWidgetProps {
  suggestedNextDate?: string;
}

function NextDate({ suggestedDate, nextDate }: { suggestedDate: Date; nextDate: Date | null }) {
  const date = nextDate || suggestedDate;

  return (
    <Stack spacing={0} alignItems='center'>
      <Typography align='center'>Your {nextDate ? '' : 'suggested'} next check-in date is:</Typography>
      <Typography align='center' variant='h1'>
        {format(date, 'MMM d, yyyy')}
      </Typography>
      <Typography align='center' fontWeight={600}>
        at {format(date, 'h:mm a')}
      </Typography>
    </Stack>
  );
}

export function ScheduleCheckInWidget({ suggestedNextDate, isSuccess }: ScheduleCheckInWidgetProps) {
  const suggestedDate = new Date(suggestedNextDate!);
  const [nextDate, setNextDate] = useState<Date | null>(null);
  const [confirmNextDate, setConfirmNextDate] = useState(false);
  const screenSize = useScreenSize();
  const theme = useTheme();
  const { scheduleCheckIn } = useOrganization();
  const { addConversationMessage, conversationId } = useConversation();
  const { data: conversationData } = useCurrentConversation();

  const checkInAvailability = useMemo(() => {
    if (conversationData === null) {
      return null;
    }

    const castedData = conversationData as {
      checkInAvailabilityCalendar: {
        [month: number]: string;
      };
    };

    const convertedData = {
      ...castedData,
      checkInAvailabilityCalendar: Object.entries(castedData.checkInAvailabilityCalendar).reduce(
        (map, current) => {
          map[Number(current[0])] = new Date(current[1]);
          return map;
        },
        {} as { [month: number]: Date }
      ),
    };

    return convertedData.checkInAvailabilityCalendar;
  }, [conversationData]);

  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const datePickerButtonRef = useRef<HTMLButtonElement | null>(null);

  const [loading, setLoading] = useState(false);

  const [scheduled, setScheduled] = useState(false);
  const schedule = async () => {
    if (!nextDate) {
      return;
    }

    try {
      setLoading(true);

      await scheduleCheckIn(nextDate);

      setConfirmNextDate(false);
      setScheduled(true);
    } finally {
      setLoading(false);
    }
  };

  const complete = isSuccess === true || isSuccess === false || scheduled;

  const scheduledMessageLock = useRef(false);
  useEffect(() => {
    if (scheduled && !scheduledMessageLock.current) {
      scheduledMessageLock.current = true;

      addConversationMessage({
        conversationId: conversationId!,
        message: nextDate!.toISOString(),
        includeMessageInConversation: false,
      }).catch((e) => {
        throw e;
      });
    }
  }, [scheduled, nextDate, conversationId, addConversationMessage]);

  return (
    <Fade in={true}>
      <OutlineContainer background={theme.palette.background.default}>
        <OutlineContainerSection>
          <Stack alignItems='center' spacing={5}>
            <Typography align='center' variant='h3'>
              Set up your next Bookkeeping Check-In
            </Typography>

            <NextDate suggestedDate={suggestedDate} nextDate={nextDate} />

            <Stack direction={screenSize === ScreenSize.SMALL ? 'column' : 'row'} spacing={1} width='100%'>
              <Button
                variant='contained'
                color='primary'
                disabled={complete || loading}
                onClick={() => {
                  setNextDate(suggestedDate);
                  setConfirmNextDate(true);
                }}
              >
                <TickCircle size='1rem' style={{ marginRight: theme.spacing(2), flexShrink: 0 }} />
                <span>Book Check-In</span>
              </Button>

              <Button
                variant='outlined'
                color='neutral'
                disabled={complete || loading}
                onClick={() => setDatePickerOpen(true)}
                ref={datePickerButtonRef}
              >
                <Calendar size='1rem' style={{ marginRight: theme.spacing(2), flexShrink: 0 }} />
                <span>Pick another date and time</span>
              </Button>
              <Popover
                open={datePickerOpen}
                onClose={() => setDatePickerOpen(false)}
                anchorEl={datePickerButtonRef.current}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  horizontal: 'center',
                  vertical: 'top',
                }}
              >
                {checkInAvailability ? (
                  <StaticDateTimePicker
                    defaultValue={suggestedDate}
                    shouldDisableDate={(date) => {
                      const CHECK_IN_BUFFER = 7;

                      const now = new Date();
                      if (date <= now) {
                        return true;
                      }

                      const earliestAvailableCheckInDate = checkInAvailability[now.getMonth() + 1];

                      if (earliestAvailableCheckInDate < now) {
                        return date < addDays(now, CHECK_IN_BUFFER);
                      } else {
                        return date < addDays(earliestAvailableCheckInDate, CHECK_IN_BUFFER);
                      }
                    }}
                    onClose={() => setDatePickerOpen(false)}
                    onAccept={(value) => {
                      setNextDate(value);
                      setDatePickerOpen(false);
                      setConfirmNextDate(true);
                    }}
                  />
                ) : (
                  <Stack justifyContent='center' alignItems='center' minWidth={200} minHeight={200}>
                    <CircularProgress />
                  </Stack>
                )}
              </Popover>

              <ConfirmDialog
                open={confirmNextDate}
                onClose={() => setConfirmNextDate(false)}
                content={
                  nextDate && (
                    <Typography>
                      Are you sure you want to schedule your next Bookkeeping Check-In for {format(nextDate, 'MMM d, yyyy')} at{' '}
                      {format(nextDate, 'h:mm a')}?
                    </Typography>
                  )
                }
                onConfirm={schedule}
              />
            </Stack>
          </Stack>
        </OutlineContainerSection>
      </OutlineContainer>
    </Fade>
  );
}
