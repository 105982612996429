import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { AddCircle, CloseCircle } from 'iconsax-react';
import { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { DocumentNote, useAdmin } from '../../../api';
import { Button, ThreeColumn } from '../../../components';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../../../theme';

function ZeroState() {
  const theme = useTheme();
  return (
    <Stack minHeight={theme.spacing(20)} justifyContent='center' alignItems='center'>
      <Typography>No notes</Typography>
    </Stack>
  );
}

interface NoteProps {
  note: DocumentNote;
}
function Note({ note }: NoteProps) {
  const theme = useTheme();

  return (
    <Stack
      paddingX={theme.spacing(SMALL_HORIZONTAL_SPACING)}
      paddingY={theme.spacing(SMALL_VERTICAL_SPACING)}
      borderRadius={theme.roundedCorners(5)}
      spacing={SMALL_VERTICAL_SPACING}
      style={{
        background: theme.palette.background2.default,
      }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='small'>{note.source}</Typography>
        <Typography variant='small'>{format(note.created, 'MMM d, yyyy')}</Typography>
      </Stack>

      <Typography>{note.content}</Typography>
    </Stack>
  );
}

const useDocumentNoteData = (organizationId: string | null, documentId: string | null) => {
  const { documents, fetchDocuments, createDocumentNote: createDocumentNoteApi } = useAdmin();

  useEffect(() => {
    if (!documentId) {
      return;
    }

    fetchDocuments(documentId).catch((e) => {
      throw e;
    });
  }, [fetchDocuments, documentId]);

  const createDocumentNote = useCallback(
    async (note: string) => {
      if (!organizationId || !documentId) {
        return;
      }

      await createDocumentNoteApi(organizationId, documentId, note);
    },
    [createDocumentNoteApi, organizationId, documentId]
  );

  const notes = useMemo(() => {
    if (!organizationId || !documentId) {
      return null;
    }

    const document = documents[organizationId].find((d) => d.id === documentId);
    if (!document) {
      return null;
    }

    return document.notes || null;
  }, [documents, documentId, organizationId]);

  return {
    createDocumentNote,
    notes,
  };
};

export interface DocumentNotesDialogProps {
  organizationId: string | null;
  openForDocumentId: string | null;
  onClose: () => void;
}

export function DocumentNotesDialog({ organizationId, openForDocumentId, onClose }: DocumentNotesDialogProps) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [newNote, setNewNote] = useState('');
  const { notes, createDocumentNote } = useDocumentNoteData(organizationId, openForDocumentId);

  const create = async (note: string) => {
    try {
      setLoading(true);
      setNewNote('');

      await createDocumentNote(note);
    } finally {
      setLoading(false);
    }
  };

  let notesContent;
  if (openForDocumentId === null) {
    notesContent = <CircularProgress />;
  } else if (notes?.length) {
    notesContent = notes.map((n) => <Note key={n.id} note={n} />);
  } else {
    notesContent = <ZeroState />;
  }

  return (
    <Dialog open={!!openForDocumentId} onClose={onClose}>
      <DialogTitle>
        <ThreeColumn $mainColumn align='center'>
          <span></span>
          <span>Document Notes</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          minHeight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        <Stack
          paddingY={theme.spacing(SMALL_VERTICAL_SPACING)}
          paddingX={theme.spacing(SMALL_HORIZONTAL_SPACING)}
          spacing={theme.spacing(SMALL_VERTICAL_SPACING)}
          alignItems='stretch'
          minHeight='0'
          flex={1}
          overflow='auto'
        >
          {notesContent}
        </Stack>

        <Stack
          paddingX={theme.spacing(SMALL_HORIZONTAL_SPACING)}
          direction='row'
          spacing={0}
          onKeyDown={async (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter' && newNote) {
              await create(newNote);
            }
          }}
        >
          <TextField
            size='small'
            multiline
            placeholder='Add note...'
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            disabled={loading}
            style={{
              flex: 1,
            }}
          />

          <IconButton disabled={!newNote || loading} onClick={() => create(newNote)}>
            {loading ? (
              <CircularProgress size='1rem' />
            ) : (
              <AddCircle color={newNote && !loading ? theme.palette.primary.main : theme.palette.text.disabled} />
            )}
          </IconButton>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='neutral' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
