import { Box, IconButton, Stack, Typography, alpha, useTheme } from '@mui/material';
import { Copy, CopySuccess } from 'iconsax-react';
import { useCallback, useState } from 'react';
import { appEnv, useSession } from '../api';

export function documentEmailAddress(organizationId: string, emailIdentifier: string | null) {
  if (appEnv === 'app') {
    if (emailIdentifier) {
      return `documents+${emailIdentifier}@otter.ca`;
    } else {
      return `documents+${organizationId}@otter.ca`;
    }
  } else {
    if (emailIdentifier) {
      return `documents-dev+${emailIdentifier}@otter.ca`;
    } else {
      return `documents-dev+${organizationId}@otter.ca`;
    }
  }
}

export function DocumentEmailAddress({ ...props }) {
  const theme = useTheme();
  const { session, organization } = useSession();
  const [emailCopied, setEmailCopied] = useState(false);

  const copy = useCallback(async () => {
    if (!session || !organization) {
      return;
    }

    const documentEmail = documentEmailAddress(session.organizationId, organization.emailIdentifier);
    setEmailCopied(true);
    await navigator.clipboard.writeText(documentEmail);
  }, [session, organization]);

  return (
    <Stack spacing={1} {...props}>
      <Typography variant='h5'>Your unique email address:</Typography>
      <Stack
        flexDirection='row'
        alignItems='center'
        bgcolor={alpha(theme.palette.text.primary, 0.1)}
        borderRadius={theme.roundedCorners(5)}
        padding={theme.spacing(2)}
      >
        <Box flex={1} overflow='hidden' whiteSpace='nowrap' textOverflow='ellipsis'>
          {documentEmailAddress(session!.organizationId, organization?.emailIdentifier || null)}
        </Box>
        <IconButton style={{ flex: 0, margin: 0 }} onClick={copy}>
          {emailCopied ? (
            <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
          ) : (
            <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
          )}
        </IconButton>
      </Stack>
    </Stack>
  );
}
