import { AutoMode, Delete } from '@mui/icons-material';
import {
  Alert,
  Badge,
  Box,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Button as MuiButton,
  Paper,
  Popper,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';
import { DocumentText1, Eye, Filter } from 'iconsax-react';
import { RefObject, createRef, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Document,
  Journal,
  MatchGroup,
  MatchStatus,
  Organization,
  OtterProcessingStatus,
  TotalMismatchResolution,
  Transaction,
  TransactionDocumentMatch,
  useAdmin,
} from '../../../api/index.tsx';
import {
  AdminJournalSelect,
  AdminOrganizationSelect,
  Button,
  ConfirmDialog,
  PageBody,
  PageContainer,
  PageHeader,
  Search,
  useSelectedOrganization,
} from '../../../components/index.ts';
import { formatAmount } from '../../../utils/currencies.ts';
import { dateIsWithinFY, getDateString } from '../../../utils/date-utils.ts';
import { FilterMenu, FilterSettings } from './filter-menu.tsx';

const UNKNOWN_NAME = 'Unknown';
const UNKNOWN_DATE = 'Unknown Date';
const UNKNOWN_AMOUNT = 'Unknown Amount';

const useAdminData = (organization: Organization | null) => {
  const {
    fetchOrganizations,
    fetchJournals,
    fetchTransactions,
    fetchDocuments,
    fetchTransactionDocumentMatches,
    fetchTransactionsMissingDocuments,
    fetchDocumentsMissingTransactions,
    organizations,
    transactions: transactionMap,
    documents: documentMap,
    journals,
    transactionDocumentMatches,
    transactionsMissingDocuments: txMissingDocsMap,
    documentsMissingTransactions: docMissingTxMap,
    createTransactionDocumentMatches,
    previewTransactionDocumentMatches,
    deleteTransactionDocumentMatches,
    updateMatchGroup,
    autoMatch,
    autoMatchScores,
    updateTransaction: updateTransactionApi,
    updateDocument: updateDocumentApi,
  } = useAdmin();

  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [matchesLoading, setMatchesLoading] = useState(false);

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });
  }, [fetchOrganizations]);

  useEffect(() => {
    if (!organization) {
      return;
    }

    fetchJournals(organization.id!).catch((e) => {
      throw e;
    });
  }, [fetchJournals, organization]);

  useEffect(() => {
    if (!organization) {
      return;
    }

    setTransactionsLoading(true);

    Promise.all([fetchTransactions(organization.id!), fetchTransactionsMissingDocuments(organization.id!)])
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        setTransactionsLoading(false);
      });
  }, [fetchTransactions, fetchTransactionsMissingDocuments, fetchTransactionDocumentMatches, organization]);

  useEffect(() => {
    if (!organization) {
      return;
    }

    setMatchesLoading(true);

    fetchTransactionDocumentMatches(organization.id!)
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        setMatchesLoading(false);
      });
  }, [fetchTransactionDocumentMatches, fetchTransactionsMissingDocuments, organization]);

  useEffect(() => {
    if (!organization) {
      return;
    }

    setDocumentsLoading(true);

    Promise.all([fetchDocuments(organization.id!), fetchDocumentsMissingTransactions(organization.id!)])
      .catch((e) => {
        throw e;
      })
      .finally(() => {
        setDocumentsLoading(false);
      });
  }, [fetchDocuments, fetchDocumentsMissingTransactions, organization]);

  const transactions = useMemo(() => {
    if (!organization || !transactionMap[organization.id!]) {
      return null;
    }

    const orgTransactions = transactionMap[organization.id!];

    return orgTransactions;
  }, [transactionMap, organization]);

  const documents = useMemo(() => {
    if (!organization || !documentMap[organization.id!]) {
      return null;
    }

    const orgDocuments = documentMap[organization.id!];

    return orgDocuments.map((d) => {
      return {
        ...d,
        name: `${d.merchantName || 'Unknown Merchant'}-${d.date ? getDateString(d.date) : 'Unknown Date'}`,
      };
    });
  }, [documentMap, organization]);

  const journalsForOrg = useMemo(() => {
    if (!organization) {
      return [];
    }

    return journals[organization.id!] || [];
  }, [organization, journals]);

  const { matchesByTransactionId, matchesByDocumentId, matchGroupsByTransactionId, matchGroupsByDocumentId } = useMemo(() => {
    if (!organization || !transactionDocumentMatches[organization.id!]) {
      return {
        matchesByTransactionId: {},
        matchesByDocumentId: {},
        matchGroupsByTransactionId: {},
        matchGroupsByDocumentId: {},
      };
    }

    const matchesByTransactionId = {} as { [transactionId: string]: string[] };
    const matchesByDocumentId = {} as { [documentId: string]: string[] };
    const matchGroupsByTransactionId = {} as { [transactionId: string]: MatchGroup };
    const matchGroupsByDocumentId = {} as { [documentId: string]: MatchGroup };
    for (const group of transactionDocumentMatches[organization.id!] || []) {
      for (const match of group.matches) {
        if (!matchesByTransactionId[match.transactionId]) {
          matchesByTransactionId[match.transactionId] = [];
        }

        matchesByTransactionId[match.transactionId].push(match.documentId);

        if (!matchesByDocumentId[match.documentId]) {
          matchesByDocumentId[match.documentId] = [];
        }

        matchesByDocumentId[match.documentId].push(match.transactionId);

        matchGroupsByTransactionId[match.transactionId] = group;
        matchGroupsByDocumentId[match.documentId] = group;
      }
    }
    return {
      matchesByDocumentId,
      matchesByTransactionId,
      matchGroupsByTransactionId,
      matchGroupsByDocumentId,
    };
  }, [organization, transactionDocumentMatches]);

  const transactionsMissingDocuments = useMemo(() => {
    if (!organization) {
      return new Set();
    }

    return txMissingDocsMap[organization.id!] || new Set();
  }, [organization, txMissingDocsMap]);

  const documentsMissingTransactions = useMemo(() => {
    if (!organization) {
      return new Set();
    }

    return docMissingTxMap[organization.id!] || new Set();
  }, [organization, docMissingTxMap]);

  const updateTransactionDocumentMatchGroup = useCallback(
    async (changes: Partial<MatchGroup> & { id: string }) => {
      if (!organization) {
        return;
      }

      await updateMatchGroup(organization.id!, changes);
    },
    [updateMatchGroup, organization]
  );

  const ignoreDocumentMatchRequirement = useCallback(
    async (transactionId: string) => {
      if (!organization) {
        return;
      }

      await updateTransactionApi(organization.id!, transactionId, { ignoreMatchRequirement: true });
      await fetchTransactionsMissingDocuments(organization.id!);
    },
    [updateTransactionApi, organization, fetchTransactionsMissingDocuments]
  );

  const ignoreTransactionMatchRequirement = useCallback(
    async (documentId: string) => {
      if (!organization) {
        return;
      }

      await updateDocumentApi(organization.id!, documentId, { ignoreMatchRequirement: true });
      await fetchDocumentsMissingTransactions(organization.id!);
    },
    [updateDocumentApi, organization, fetchDocumentsMissingTransactions]
  );

  return {
    organizations,
    transactions,
    documents,
    journals: journalsForOrg,
    matchesByTransactionId,
    matchesByDocumentId,
    transactionsMissingDocuments,
    documentsMissingTransactions,
    matchGroupsByTransactionId,
    matchGroupsByDocumentId,
    createTransactionDocumentMatches,
    previewTransactionDocumentMatches,
    deleteTransactionDocumentMatches,
    updateTransactionDocumentMatchGroup,
    autoMatch,
    autoMatchScores,
    transactionsLoading,
    documentsLoading,
    matchesLoading,
    ignoreDocumentMatchRequirement,
    ignoreTransactionMatchRequirement,
  };
};

interface MatchConfirmDialogProps {
  openFor: Array<{ type: string; transactionTotal?: string; documentTotal?: string }> | null;
  transactions: Transaction[];
  documents: Document[];
  onClose: () => void;
  onConfirm: (resolution: TotalMismatchResolution | null) => Promise<void>;
}
function MatchConfirmDialog({ openFor, documents, onClose, onConfirm }: MatchConfirmDialogProps) {
  const [loading, setLoading] = useState(false);
  const [resolution, setResoution] = useState<TotalMismatchResolution | null>(null);

  useEffect(() => {
    if (!openFor) {
      return;
    }

    const totalMismatch = !!openFor.find((warning) => warning.type === 'MATCH_TOTAL_DIFFERENCE');
    setResoution(totalMismatch ? TotalMismatchResolution.NONE : null);
  }, [openFor]);

  const confirm = useCallback(async () => {
    try {
      setLoading(true);
      await onConfirm(resolution);
    } finally {
      setLoading(false);
    }
  }, [onConfirm, resolution]);

  const warningsByType = openFor
    ? openFor.reduce(
        (map, current) => {
          map[current.type] = current;
          return map;
        },
        {} as { [type: string]: { type: string; transactionTotal?: string; documentTotal?: string } }
      )
    : null;

  const error =
    !!warningsByType &&
    !!(
      warningsByType['EXPENSE_INCOME_MISMATCH'] ||
      warningsByType['CATEGORY_MISTMATCH'] ||
      warningsByType['INCOMING_OUTGOING_DOCUMENT_MIX'] ||
      warningsByType['EXPENSE_INCOME_TRANSACTION_MIX'] ||
      warningsByType['TRANSACTION_CATEGORY_MIX'] ||
      warningsByType['DOCUMENT_CATEGORY_MIX']
    );

  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }); //TODO: Currency should come from somewhere

  const matchTotalDifferenceWarning = warningsByType ? warningsByType['MATCH_TOTAL_DIFFERENCE'] : null;
  const formattedTransactionTotal = matchTotalDifferenceWarning
    ? amountFormatter.format(parseFloat(matchTotalDifferenceWarning.transactionTotal!))
    : '0';
  const formattedDocumentTotal = matchTotalDifferenceWarning ? amountFormatter.format(parseFloat(matchTotalDifferenceWarning.documentTotal!)) : '0';

  const isExpense = documents.length && !documents[0].outgoing;
  const feesDisabled =
    !matchTotalDifferenceWarning?.transactionTotal ||
    !matchTotalDifferenceWarning.documentTotal ||
    parseFloat(matchTotalDifferenceWarning.documentTotal) < 0 ||
    (parseFloat(matchTotalDifferenceWarning.transactionTotal) > parseFloat(matchTotalDifferenceWarning.documentTotal) && !isExpense) ||
    (parseFloat(matchTotalDifferenceWarning.transactionTotal) < parseFloat(matchTotalDifferenceWarning.documentTotal) && isExpense);

  return (
    <Dialog open={!!openFor} onClose={onClose}>
      <DialogTitle>Warning</DialogTitle>
      <DialogContent>
        <Stack>
          {matchTotalDifferenceWarning && (
            <>
              <Typography>
                Transaction total ({formattedTransactionTotal}) does not match document total ({formattedDocumentTotal}). What should be done to
                resolve this difference?
              </Typography>
              <FormControl fullWidth>
                <InputLabel id='match-total-difference-resolution-label' shrink={!!resolution}>
                  Resolution
                </InputLabel>
                <Select
                  notched={!!resolution}
                  label='Resolution'
                  labelId='match-total-difference-resolution-label'
                  value={resolution}
                  onChange={(event) => setResoution(event.target.value as TotalMismatchResolution)}
                >
                  <MenuItem value={TotalMismatchResolution.FX}>Attribute to FX gain/loss</MenuItem>
                  <MenuItem value={TotalMismatchResolution.FEES} disabled={!!feesDisabled}>
                    Attribute to fees
                  </MenuItem>
                  <MenuItem value={TotalMismatchResolution.NONE}>Do nothing</MenuItem>
                </Select>
              </FormControl>
            </>
          )}

          {warningsByType && warningsByType['EXPENSE_INCOME_MISMATCH'] && (
            <Alert severity='error'>The document(s) and transaction(s) included in this match do not agree on expense vs. income</Alert>
          )}

          {warningsByType && warningsByType['CATEGORY_MISTMATCH'] && (
            <Alert severity='error'>The document(s) and transaction(s) included in this match do not agree on a categorization</Alert>
          )}

          {warningsByType && warningsByType['INCOMING_OUTGOING_DOCUMENT_MIX'] && (
            <Alert severity='error'>The document(s) included in this match do not agree on incoming vs. outgoing</Alert>
          )}

          {warningsByType && warningsByType['EXPENSE_INCOME_TRANSACTION_MIX'] && (
            <Alert severity='error'>The transaction(s) included in this match do not agree on expense vs. income</Alert>
          )}

          {warningsByType && warningsByType['TRANSACTION_CATEGORY_MIX'] && (
            <Alert severity='error'>The transaction(s) included in this match do not agree on a category</Alert>
          )}

          {warningsByType && warningsByType['DOCUMENT_CATEGORY_MIX'] && (
            <Alert severity='error'>The document(s) included in this match do not agree on a category</Alert>
          )}

          {warningsByType && warningsByType['PAID_UNPAID_DOCUMENT_MIX'] && (
            <Alert severity='warning'>Some documents included in this match are paid and some are unpaid</Alert>
          )}

          {warningsByType && warningsByType['TRANSACTION_ACCOUNT_MIX'] && (
            <Alert severity='warning'>This match contains transactions from different cash/credit accounts</Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='neutral'
          onClick={() => {
            onClose();
          }}
          disabled={loading}
        >
          Close
        </Button>
        <Button variant='contained' color='primary' onClick={confirm} disabled={loading || error}>
          {loading ? <CircularProgress /> : 'Continue'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const StatusBadge = forwardRef(({ matched, disabled }: { matched: boolean; disabled: boolean }, ref) => {
  const theme = useTheme();

  let color: string;
  if (disabled) {
    color = theme.palette.text.disabled;
  } else if (matched) {
    color = theme.palette.primary.main;
  } else {
    color = theme.palette.warning.main;
  }

  let border: string;
  if (disabled) {
    border = `1px solid ${theme.palette.mode === 'dark' ? theme.palette.border.main : theme.palette.text.disabled}`;
  } else if (matched) {
    border = 'none';
  } else {
    border = `1px solid ${theme.palette.warning.main}`;
  }

  let background: string;
  if (disabled) {
    background = 'none';
  } else if (matched) {
    background = alpha(theme.palette.primary.main, 0.5);
  } else {
    background = 'none';
  }

  return (
    <Tooltip title={matched ? 'Matched' : 'Unmatched'}>
      <Box
        ref={ref}
        border={border}
        style={{
          background,
        }}
        lineHeight='1.5rem'
        color={color}
        borderRadius='100%'
        width='1.5rem'
        height='1.5rem'
      >
        {matched ? 'M' : 'U'}
      </Box>
    </Tooltip>
  );
});

interface Item {
  id: string;
  name: string | null;
  date: Date | null;
  amount: string | null;
  currency: string;
  isMatched: boolean;
  ignoreMatchRequirement: boolean;
}
interface ItemListProps {
  itemsLoading: boolean;
  anchorItem: string | null;
  matchingMenu?: React.ReactNode;
  selected: Set<string>;
  title: string;
  items: Item[];
  loadingItems: Set<string>;
  organization: Organization | null;
  journal: Journal | null;
  filteringFn: (searchCriteria: string[], item: Item) => boolean;
  onItemSelect: (item: Item) => void;
  onItemDeselect: (item: Item) => void;
  actions?: (item: Item) => React.ReactNode;
}
const ItemList = forwardRef<HTMLDivElement, ItemListProps>(
  (
    {
      itemsLoading,
      anchorItem,
      matchingMenu,
      selected,
      title,
      items,
      loadingItems,
      organization,
      journal,
      filteringFn,
      onItemSelect,
      onItemDeselect,
      actions,
    }: ItemListProps,
    ref
  ) => {
    const theme = useTheme();
    const [searchCriteria, setSearchCriteria] = useState<string[]>([]);

    const [filterSettings, setFilterSettings] = useState<FilterSettings>({
      minDate: null,
      maxDate: null,
      restrictToFy: true,
    });

    const appliedFilterCount = useMemo(() => {
      let count = 0;

      if (filterSettings.minDate) {
        count++;
      }

      if (filterSettings.maxDate) {
        count++;
      }

      if (!filterSettings.restrictToFy) {
        count++;
      }

      return count;
    }, [filterSettings]);

    const itemRefs = useRef<{ [id: string]: RefObject<HTMLDivElement> }>({});

    const filterConfigButtonRef = useRef<HTMLButtonElement | null>(null);
    const [showFilterConfig, setShowFilterConfig] = useState(false);

    const filteredItems = useMemo(() => {
      const filtered = [];
      for (const item of items) {
        if (!journal || !organization) {
          continue;
        }

        if (
          item.date &&
          ((filterSettings.minDate && item.date < filterSettings.minDate) || (filterSettings.maxDate && item.date > filterSettings.maxDate))
        ) {
          continue;
        }

        if (item.date && filterSettings.restrictToFy && !dateIsWithinFY(item.date, journal.fy, organization.fyEndMonth)) {
          continue;
        }

        const passedFilter = filteringFn(searchCriteria, item);

        if (passedFilter) {
          filtered.push(item);

          if (!itemRefs.current[item.id]) {
            itemRefs.current[item.id] = createRef();
          }
        }
      }
      return filtered;
    }, [items, searchCriteria, filterSettings, filteringFn, journal, organization]);

    const matchingMenuOpen = useMemo(() => {
      return !!filteredItems.find((i) => anchorItem === i.id);
    }, [filteredItems, anchorItem]);

    const anchorEl = useMemo<HTMLElement | null>(() => {
      if (!anchorItem) {
        return null;
      }

      return filteredItems.length ? itemRefs.current[anchorItem]?.current || null : null;
    }, [filteredItems, anchorItem]);

    return (
      <Stack flex={1} style={{ minHeight: 0 }}>
        <Typography variant='h4'>{title}</Typography>

        <Stack direction='row'>
          <Search searchCriteria={searchCriteria} onSearchCriteriaUpdate={(criteria) => setSearchCriteria(criteria)} style={{ flex: 1 }} />

          <Tooltip title='Filter Settings'>
            <Badge badgeContent={appliedFilterCount} color='primary' overlap='circular'>
              <IconButton onClick={() => setShowFilterConfig((existing) => !existing)} ref={filterConfigButtonRef}>
                <Filter color={theme.palette.primary.main} />
              </IconButton>
            </Badge>
          </Tooltip>
          <FilterMenu
            open={showFilterConfig}
            anchorEl={filterConfigButtonRef.current}
            filterSettings={filterSettings}
            onClose={() => setShowFilterConfig(false)}
            onChange={(changes) => setFilterSettings(changes)}
          />
        </Stack>

        <Stack
          spacing={theme.spacing(2)}
          style={{
            flex: 1,
            overflow: 'auto',
          }}
          alignItems={itemsLoading ? 'center' : 'stretch'}
          justifyContent={itemsLoading ? 'center' : 'start'}
          ref={ref}
        >
          {itemsLoading && <CircularProgress />}
          {!itemsLoading &&
            filteredItems.map((i) => {
              const loading = loadingItems.has(i.id);
              const verticalSpacing = theme.spacing(2);
              const horizontalSpacing = theme.spacing(3);

              return (
                <Stack
                  key={i.id}
                  direction='row'
                  alignItems='stretch'
                  style={{
                    border: selected.has(i.id) ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.border.main}`,
                    borderRadius: theme.roundedCorners(5),
                    cursor: loading ? 'auto' : 'pointer',
                  }}
                  spacing={0}
                  onClick={() => {
                    if (loadingItems.has(i.id)) {
                      return;
                    }

                    if (selected.has(i.id)) {
                      onItemDeselect(i);
                    } else {
                      onItemSelect(i);
                    }
                  }}
                  ref={itemRefs.current[i.id]}
                >
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    paddingTop={verticalSpacing}
                    paddingBottom={verticalSpacing}
                    paddingLeft={horizontalSpacing}
                    paddingRight={horizontalSpacing}
                    width='3rem'
                    textAlign='center'
                    borderRight={selected.has(i.id) ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.border.main}`}
                  >
                    <Tooltip title={i.isMatched ? 'Matched' : 'Unmatched'}>
                      <StatusBadge disabled={loading} matched={i.isMatched} />
                    </Tooltip>
                  </Box>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    paddingTop={verticalSpacing}
                    paddingBottom={verticalSpacing}
                    paddingLeft={horizontalSpacing}
                    paddingRight={horizontalSpacing}
                    width='7.5rem'
                    textAlign='center'
                  >
                    <Typography>{i.date ? getDateString(i.date) : UNKNOWN_DATE}</Typography>
                  </Box>
                  <Box
                    flex={1}
                    display='flex'
                    alignItems='center'
                    paddingTop={verticalSpacing}
                    paddingBottom={verticalSpacing}
                    paddingLeft={horizontalSpacing}
                    paddingRight={horizontalSpacing}
                    borderLeft={selected.has(i.id) ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.border.main}`}
                    borderRight={selected.has(i.id) ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.border.main}`}
                  >
                    <Typography>{i.name || UNKNOWN_NAME}</Typography>
                  </Box>
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='end'
                    paddingTop={verticalSpacing}
                    paddingBottom={verticalSpacing}
                    paddingLeft={horizontalSpacing}
                    paddingRight={horizontalSpacing}
                    minWidth='5rem'
                    borderRight={selected.has(i.id) ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.border.main}`}
                  >
                    <Typography>{i.amount ? formatAmount(i.amount, i.currency || 'CAD') : UNKNOWN_AMOUNT}</Typography>
                  </Box>
                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={0}
                    paddingTop={verticalSpacing}
                    paddingBottom={verticalSpacing}
                    paddingLeft={horizontalSpacing}
                    paddingRight={horizontalSpacing}
                  >
                    {actions ? actions(i) : undefined}
                  </Stack>
                </Stack>
              );
            })}
        </Stack>

        {matchingMenuOpen && (
          <Popper open={matchingMenuOpen} anchorEl={anchorEl}>
            <Paper
              style={{
                padding: theme.spacing(5),
                borderRadius: theme.roundedCorners(5),
                border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.secondary[300] : theme.palette.neutral[300]}`,
              }}
            >
              {matchingMenu}
            </Paper>
          </Popper>
        )}
      </Stack>
    );
  }
);

const useAutoMatchScorePreview = (selectedOrg: Organization | null, selectedTransactionIds: Set<string>, selectedDocumentIds: Set<string>) => {
  const { autoMatchScores } = useAdminData(selectedOrg);
  const [autoMatchScoringLoading, setAutoMatchScoringLoading] = useState(false);
  const [autoMatchScorePreview, setAutoMatchScorePreview] = useState<null | {
    scores: {
      name: number;
      amount: number;
      date: number;
      matchHints: number;
    };
    weights: {
      name: number;
      amount: number;
      date: number;
      matchHints: number;
    };
    overall: number;
  } | null>(null);

  useEffect(() => {
    const refresh = async () => {
      if (!selectedOrg || selectedDocumentIds.size !== 1 || selectedTransactionIds.size !== 1) {
        setAutoMatchScorePreview(null);
        return;
      }

      try {
        setAutoMatchScoringLoading(true);

        const result = await autoMatchScores(selectedOrg.id!, Array.from(selectedDocumentIds), Array.from(selectedTransactionIds));

        const documentId = Array.from(selectedDocumentIds)[0];
        const scores = result.documentRawMatchScores[documentId];
        const weights = result.documentMatchWeights[documentId];
        const overall = result.documentWeightedMatchScores[documentId].overall;

        setAutoMatchScorePreview({
          scores,
          weights,
          overall,
        });
      } finally {
        setAutoMatchScoringLoading(false);
      }
    };

    refresh().catch((e) => {
      throw e;
    });
  }, [selectedDocumentIds, selectedTransactionIds, selectedOrg, autoMatchScores]);

  return {
    autoMatchScoringLoading,
    autoMatchScorePreview,
  };
};

enum Mode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}
enum AnchorItemType {
  TRANSACTION = 'TRANSACTION',
  DOCUMENT = 'DOCUMENT',
}
interface AnchorItem {
  id: string;
  type: AnchorItemType;
}
enum MatchingTab {
  ALL = 'ALL',
  UNMATCHED = 'UNMATCHED',
  UNDER_REVIEW = 'UNDER_REVIEW',
  MATCHED = 'MATCHED',
}
export function AdminMatchesPage({ ...props }) {
  const [mode, setMode] = useState(Mode.EDIT);
  const { selectedOrganization: selectedOrg, setSelectedOrganization: setSelectedOrg, previousSelectedOrganization } = useSelectedOrganization(null);
  const [selectedJournal, setSelectedJournal] = useState<Journal | null>(null);
  const {
    organizations,
    journals,
    transactions,
    documents,
    matchesByTransactionId,
    matchesByDocumentId,
    matchGroupsByDocumentId,
    matchGroupsByTransactionId,
    transactionsMissingDocuments,
    documentsMissingTransactions,
    createTransactionDocumentMatches,
    previewTransactionDocumentMatches,
    deleteTransactionDocumentMatches,
    updateTransactionDocumentMatchGroup,
    autoMatch,
    transactionsLoading,
    documentsLoading,
    matchesLoading,
    ignoreTransactionMatchRequirement: ignoreTransactionMatchRequirementApi,
    ignoreDocumentMatchRequirement: ignoreDocumentMatchRequirementApi,
  } = useAdminData(selectedOrg);
  const [selectedTransactionIds, setSelectedTransactionIds] = useState(new Set<string>());
  const [selectedDocumentIds, setSelectedDocumentIds] = useState(new Set<string>());
  const [autoConfirmDialogOpen, setAutoConfirmDialogOpen] = useState(false);
  const [tab, setTab] = useState(MatchingTab.UNMATCHED);
  const [anchorItem, setAnchorItem] = useState<AnchorItem | null>(null);
  const theme = useTheme();
  const [loading, setLoading] = useState(() => new Set<string>());
  const location = useLocation();

  const transactionListRef = useRef<HTMLDivElement | null>(null);
  const documentListRef = useRef<HTMLDivElement | null>(null);

  const [confirmIgnoreDocumentRequirementFor, setConfirmIgnoreDocumentRequirementFor] = useState<Item | null>(null);
  const [confirmIgnoreTransactionRequirementFor, setConfirmIgnoreTransactionRequirementFor] = useState<Item | null>(null);

  const deselectTransaction = useCallback(
    (id: string) => {
      if (mode === Mode.VIEW) {
        setSelectedTransactionIds(new Set());
        setSelectedDocumentIds(new Set());
      } else if (anchorItem?.type === AnchorItemType.TRANSACTION) {
        setAnchorItem(null);
        setSelectedTransactionIds(new Set());
        setSelectedDocumentIds(new Set());
      } else {
        setSelectedTransactionIds((existing) => {
          const copy = new Set(existing);
          copy.delete(id);
          return copy;
        });
      }
    },
    [mode, anchorItem]
  );

  const selectTransaction = useCallback(
    (id: string) => {
      if (mode === Mode.VIEW || !anchorItem) {
        setAnchorItem({
          id,
          type: AnchorItemType.TRANSACTION,
        });

        const documentMatches = matchesByTransactionId[id];
        const transactionMatches = documentMatches?.length === 1 ? matchesByDocumentId[documentMatches[0]] : [id];
        setSelectedTransactionIds(new Set(transactionMatches));
        setSelectedDocumentIds(new Set(documentMatches));
      } else if (anchorItem?.type === AnchorItemType.TRANSACTION && selectedTransactionIds.has(id)) {
        setAnchorItem(null);
        deselectTransaction(id);
      } else if (anchorItem?.type === AnchorItemType.TRANSACTION) {
        const documentMatches = matchesByTransactionId[id];
        const transactionMatches = documentMatches?.length === 1 ? matchesByDocumentId[documentMatches[0]] : [id];
        setSelectedTransactionIds(new Set(transactionMatches));
        setSelectedDocumentIds(new Set(documentMatches));
      } else {
        setSelectedTransactionIds((existing) => {
          const copy = new Set(existing);
          copy.add(id);
          return copy;
        });
      }
    },
    [selectedTransactionIds, deselectTransaction, matchesByTransactionId, matchesByDocumentId, mode, anchorItem]
  );

  const deselectDocument = useCallback(
    (id: string) => {
      if (mode === Mode.VIEW || anchorItem?.type === AnchorItemType.DOCUMENT) {
        setAnchorItem(null);
        setSelectedTransactionIds(new Set());
        setSelectedDocumentIds(new Set());
      } else {
        setSelectedDocumentIds((existing) => {
          const copy = new Set(existing);
          copy.delete(id);
          return copy;
        });
      }
    },
    [mode, anchorItem]
  );

  const selectDocument = useCallback(
    (id: string) => {
      if (mode === Mode.VIEW || !anchorItem) {
        setAnchorItem({
          id,
          type: AnchorItemType.DOCUMENT,
        });

        const transactionMatches = matchesByDocumentId[id];
        const documentMatches = transactionMatches?.length === 1 ? matchesByTransactionId[transactionMatches[0]] : [id];
        setSelectedDocumentIds(new Set(documentMatches));
        setSelectedTransactionIds(new Set(transactionMatches));
      } else if (anchorItem?.type === AnchorItemType.DOCUMENT && selectedDocumentIds.has(id)) {
        setAnchorItem(null);
        deselectDocument(id);
      } else if (anchorItem?.type === AnchorItemType.DOCUMENT) {
        const transactionMatches = matchesByDocumentId[id];
        const documentMatches = transactionMatches?.length === 1 ? matchesByTransactionId[transactionMatches[0]] : [id];
        setSelectedDocumentIds(new Set(documentMatches));
        setSelectedTransactionIds(new Set(transactionMatches));
      } else {
        setSelectedDocumentIds((existing) => {
          const copy = new Set(existing);
          copy.add(id);
          return copy;
        });
      }
    },
    [mode, deselectDocument, matchesByDocumentId, matchesByTransactionId, selectedDocumentIds, anchorItem]
  );

  const ignoreDocumentMatchRequirement = useCallback(
    async (transactionId: string) => {
      deselectTransaction(transactionId);
      await ignoreDocumentMatchRequirementApi(transactionId);
    },
    [ignoreDocumentMatchRequirementApi, deselectTransaction]
  );

  const ignoreTransactionMatchRequirement = useCallback(
    async (documentId: string) => {
      deselectDocument(documentId);
      await ignoreTransactionMatchRequirementApi(documentId);
    },
    [ignoreTransactionMatchRequirementApi, deselectDocument]
  );

  useEffect(() => {
    if (!transactionListRef.current || !documentListRef.current) {
      return;
    }

    transactionListRef.current.scrollTop = 0;
    documentListRef.current.scrollTop = 0;
  }, [selectedTransactionIds, selectedDocumentIds]);

  const searchPopulated = useRef(false);
  useEffect(() => {
    if (!transactions || !documents || searchPopulated.current) {
      return;
    }

    searchPopulated.current = true;

    const queryParams = new URLSearchParams(location.search);

    const transactionIdQuery = queryParams.get('transactionId');
    if (transactionIdQuery) {
      setTab(MatchingTab.MATCHED);
      selectTransaction(transactionIdQuery);
    }

    const documentIdQuery = queryParams.get('documentId');
    if (documentIdQuery) {
      setTab(MatchingTab.MATCHED);
      selectDocument(documentIdQuery);
    }
  }, [location, selectTransaction, selectDocument, documents, transactions]);

  const changeMode = (mode: Mode) => {
    if (mode === Mode.VIEW) {
      setAnchorItem(null);
    } else if (selectedTransactionIds.size === 1) {
      setAnchorItem({
        id: Array.from(selectedTransactionIds)[0],
        type: AnchorItemType.TRANSACTION,
      });
    } else if (selectedDocumentIds.size === 1) {
      setAnchorItem({
        id: Array.from(selectedDocumentIds)[0],
        type: AnchorItemType.TRANSACTION,
      });
    }

    setMode(mode);
  };

  const [showConfirmMatchDifferenceDialogFor, setShowConfirmMatchDifferenceDialogFor] = useState<Array<{
    type: string;
    transactionTotal?: string;
    documentTotal?: string;
  }> | null>(null);

  const saveMatches = async ({
    totalMismatchResolution,
    ignoreWarnings,
  }: {
    totalMismatchResolution?: TotalMismatchResolution;
    ignoreWarnings?: boolean;
  }) => {
    if (!selectedOrg) {
      return;
    }

    const loadingIds = new Set([...selectedDocumentIds, ...selectedTransactionIds]);

    try {
      setLoading((existing) => {
        const newSet = new Set([...existing, ...loadingIds]);
        return newSet;
      });

      const matches: Omit<TransactionDocumentMatch, 'id' | 'created' | 'matchStrength'>[] = [];
      for (const selectedTransactionId of selectedTransactionIds) {
        for (const selectedDocumentId of selectedDocumentIds) {
          matches.push({
            transactionId: selectedTransactionId,
            documentId: selectedDocumentId,
          });
        }
      }

      let warnings:
        | {
            type: string;
            transactionTotal?: string | undefined;
            documentTotal?: string | undefined;
          }[]
        | null = null;

      if (!ignoreWarnings) {
        warnings = await previewTransactionDocumentMatches(selectedOrg.id!, matches);
      }

      if (warnings?.length) {
        setShowConfirmMatchDifferenceDialogFor(warnings);
      } else {
        setAnchorItem(null);
        setSelectedTransactionIds(new Set());
        setSelectedDocumentIds(new Set());
        setShowConfirmMatchDifferenceDialogFor(null);
        await createTransactionDocumentMatches(selectedOrg.id!, matches, totalMismatchResolution);
      }
    } finally {
      setLoading((existing) => {
        const newSet = new Set(existing);
        for (const loading of loadingIds) {
          newSet.delete(loading);
        }
        return newSet;
      });
    }
  };

  const { autoMatchScoringLoading, autoMatchScorePreview } = useAutoMatchScorePreview(selectedOrg, selectedTransactionIds, selectedDocumentIds);

  const dirtyEdit = useMemo(() => {
    const setsEqual = (set1: Set<string>, set2: Set<string>) => {
      if (set1.size !== set2.size) {
        return false;
      }

      for (const item1 of set1) {
        if (!set2.has(item1)) {
          return false;
        }
      }

      for (const item2 of set2) {
        if (!set1.has(item2)) {
          return false;
        }
      }

      return true;
    };

    for (const selectedTransactionId of selectedTransactionIds) {
      const matchingDocumentIds = new Set(matchesByTransactionId[selectedTransactionId]);

      if (!setsEqual(matchingDocumentIds, selectedDocumentIds)) {
        return true;
      }
    }

    for (const selectedDocumentId of selectedDocumentIds) {
      const matchingTransactionIds = new Set(matchesByDocumentId[selectedDocumentId]);

      if (!setsEqual(matchingTransactionIds, selectedTransactionIds)) {
        return true;
      }
    }

    return false;
  }, [selectedTransactionIds, selectedDocumentIds, matchesByDocumentId, matchesByTransactionId]);

  const deleteMatches = async () => {
    if (!selectedOrg || dirtyEdit) {
      return;
    }

    const loadingIds = new Set([...selectedDocumentIds, ...selectedTransactionIds]);
    try {
      setLoading((existing) => {
        return new Set([...existing, ...loadingIds]);
      });

      const matches: Omit<TransactionDocumentMatch, 'id' | 'created' | 'matchStrength'>[] = [];
      for (const selectedTransactionId of selectedTransactionIds) {
        for (const selectedDocumentId of selectedDocumentIds) {
          matches.push({
            transactionId: selectedTransactionId,
            documentId: selectedDocumentId,
          });
        }
      }

      setAnchorItem(null);
      setSelectedTransactionIds(new Set());
      setSelectedDocumentIds(new Set());
      await deleteTransactionDocumentMatches(selectedOrg.id!, matches);
    } finally {
      setLoading((existing) => {
        const newSet = new Set(existing);
        for (const loading of loadingIds) {
          newSet.delete(loading);
        }
        return newSet;
      });
    }
  };

  const markAsReviewed = useCallback(async () => {
    if (!selectedTransactionIds.size) {
      return;
    }

    const group = matchGroupsByTransactionId[Array.from(selectedTransactionIds)[0]];

    const loadingIds = new Set([...selectedDocumentIds, ...selectedTransactionIds]);

    try {
      setLoading((existing) => new Set([...existing, ...loadingIds]));
      setAnchorItem(null);
      setSelectedDocumentIds(new Set());
      setSelectedTransactionIds(new Set());
      await updateTransactionDocumentMatchGroup({
        id: group.id,
        status: MatchStatus.COMPLETE,
      });
    } finally {
      setLoading((existing) => {
        const newSet = new Set(existing);
        for (const loading of loadingIds) {
          newSet.delete(loading);
        }
        return newSet;
      });
    }
  }, [updateTransactionDocumentMatchGroup, selectedTransactionIds, matchGroupsByTransactionId, selectedDocumentIds]);

  const transactionItemsLoading = useMemo(() => {
    return matchesLoading || transactionsLoading;
  }, [matchesLoading, transactionsLoading]);

  const transactionItems: Item[] | null = useMemo(() => {
    if (!transactions) {
      return null;
    }

    const transactionsById = transactions.reduce(
      (map, current) => {
        map[current.id] = current;
        return map;
      },
      {} as { [id: string]: Transaction }
    );

    const firstSelectedTransactionId = selectedTransactionIds.size ? Array.from(selectedTransactionIds)[0] : null;
    const firstSelectedTransaction = firstSelectedTransactionId ? transactionsById[firstSelectedTransactionId] : null;

    return transactions
      .filter((t) => !t.ignored)
      .filter((t) => {
        if (tab === MatchingTab.ALL) {
          return (t.approved && matchesByTransactionId[t.id]) || transactionsMissingDocuments.has(t.id);
        }

        return (
          (t.approved && MatchingTab.UNDER_REVIEW === tab && matchGroupsByTransactionId[t.id]?.status === MatchStatus.UNDER_REVIEW) ||
          (t.approved &&
            MatchingTab.MATCHED === tab &&
            matchesByTransactionId[t.id] &&
            matchGroupsByTransactionId[t.id]?.status !== MatchStatus.UNDER_REVIEW) ||
          (t.approved && MatchingTab.UNMATCHED === tab && !matchesByTransactionId[t.id] && transactionsMissingDocuments.has(t.id))
        );
      })
      .filter((t) => {
        if (!firstSelectedTransaction) {
          return true;
        }

        return firstSelectedTransaction.accountId === t.accountId;
      })
      .map((t) => {
        return {
          id: t.id,
          name: t.name,
          date: t.date,
          amount: t.amount,
          currency: t.isoCurrencyCode,
          isMatched: !!matchesByTransactionId[t.id],
          ignoreMatchRequirement: t.ignoreMatchRequirement,
        };
      })
      .sort((a, b) => {
        if (selectedTransactionIds.has(a.id) && !selectedTransactionIds.has(b.id)) {
          return -1;
        }

        if (selectedTransactionIds.has(b.id) && !selectedTransactionIds.has(a.id)) {
          return 1;
        }

        return b.date.getTime() - a.date.getTime();
      });
  }, [transactions, selectedTransactionIds, matchesByTransactionId, tab, matchGroupsByTransactionId, transactionsMissingDocuments]);

  const documentsItemsLoading = useMemo(() => {
    return matchesLoading || documentsLoading;
  }, [matchesLoading, documentsLoading]);

  const documentItems: Item[] | null = useMemo(() => {
    if (!documents) {
      return null;
    }

    const documentsById = documents.reduce(
      (map, current) => {
        map[current.id] = current;
        return map;
      },
      {} as { [id: string]: Document }
    );

    const firstSelectedDocumentId = selectedDocumentIds.size ? Array.from(selectedDocumentIds)[0] : null;
    const firstSelectedDocument = firstSelectedDocumentId ? documentsById[firstSelectedDocumentId] : null;

    return documents
      .filter((d) => !d.ignored)
      .filter((d) => {
        if (!firstSelectedDocument) {
          return true;
        }

        return firstSelectedDocument.alreadyPaid === d.alreadyPaid;
      })
      .filter((d) => {
        if (tab === MatchingTab.ALL) {
          return (d.otterProcessingStatus !== OtterProcessingStatus.PARSED && documentsMissingTransactions.has(d.id)) || matchesByDocumentId[d.id];
        }

        return (
          (MatchingTab.UNDER_REVIEW === tab && matchGroupsByDocumentId[d.id]?.status === MatchStatus.UNDER_REVIEW) ||
          (MatchingTab.MATCHED === tab && matchesByDocumentId[d.id] && matchGroupsByDocumentId[d.id]?.status !== MatchStatus.UNDER_REVIEW) ||
          (MatchingTab.UNMATCHED === tab &&
            !matchesByDocumentId[d.id] &&
            documentsMissingTransactions.has(d.id) &&
            d.otterProcessingStatus !== OtterProcessingStatus.PARSED)
        );
      })
      .map((d) => {
        return {
          id: d.id,
          name: d.merchantName,
          date: d.date,
          amount: d.afterTax,
          currency: d.currency,
          isMatched: !!matchesByDocumentId[d.id],
          ignoreMatchRequirement: d.ignoreMatchRequirement,
        };
      })
      .sort((a, b) => {
        if (selectedDocumentIds.has(a.id) && !selectedDocumentIds.has(b.id)) {
          return -1;
        }
        if (selectedDocumentIds.has(b.id) && !selectedDocumentIds.has(a.id)) {
          return 1;
        }
        if (!b.date) {
          return -1;
        }
        if (!a.date) {
          return 1;
        }
        return b.date.getTime() - a.date.getTime();
      });
  }, [documents, selectedDocumentIds, matchesByDocumentId, matchGroupsByDocumentId, tab, documentsMissingTransactions]);

  const matchChanged = useMemo(() => {
    const expectedDocumentIds = new Set<string>();
    const expectedTransactionIds = new Set<string>();

    for (const documentId of selectedDocumentIds) {
      const group = matchGroupsByDocumentId[documentId];
      if (!group) {
        continue;
      }

      for (const match of group.matches) {
        expectedDocumentIds.add(match.documentId);
        expectedTransactionIds.add(match.transactionId);
      }
    }

    for (const transactionId of selectedTransactionIds) {
      const group = matchGroupsByTransactionId[transactionId];
      if (!group) {
        continue;
      }

      for (const match of group.matches) {
        expectedDocumentIds.add(match.documentId);
        expectedTransactionIds.add(match.transactionId);
      }
    }

    for (const expectedDocumentId of expectedDocumentIds) {
      if (!selectedDocumentIds.has(expectedDocumentId)) {
        return true;
      }
    }

    for (const expectedTransactionId of expectedTransactionIds) {
      if (!selectedTransactionIds.has(expectedTransactionId)) {
        return true;
      }
    }

    return false;
  }, [selectedDocumentIds, selectedTransactionIds, matchGroupsByDocumentId, matchGroupsByTransactionId]);

  const itemFilterFn = (searchCriteria: string[], item: Item) => {
    if (!searchCriteria.length) {
      return true;
    }

    for (const searchCriterion of searchCriteria) {
      if (`id::${item.id}` === searchCriterion) {
        return true;
      }

      if (item.id.includes(searchCriterion)) {
        return true;
      }

      if ((item.name || UNKNOWN_NAME).toLowerCase().includes(searchCriterion.toLowerCase())) {
        return true;
      }

      const searchAmount = parseFloat(searchCriterion);
      const itemAmount = item.amount ? parseFloat(item.amount) : null;
      if (String(itemAmount).includes(String(searchAmount))) {
        return true;
      }

      const dateStr = item.date ? getDateString(item.date) : UNKNOWN_DATE;
      if (searchCriterion.toLowerCase().includes(dateStr.toLowerCase()) || dateStr.toLowerCase().includes(searchCriterion.toLowerCase())) {
        return true;
      }
      const formattedAmount = item.amount ? formatAmount(item.amount, item.currency) : UNKNOWN_AMOUNT;
      if (formattedAmount && (formattedAmount.includes(searchCriterion.toLowerCase()) || searchCriterion.includes(formattedAmount))) {
        return true;
      }
    }

    return false;
  };

  const executeAutoMatch = async () => {
    if (!selectedOrg) {
      return;
    }

    const loadingIds = new Set([...selectedDocumentIds, ...selectedTransactionIds]);

    try {
      setLoading((existing) => new Set([...existing, ...loadingIds]));
      await autoMatch(selectedOrg.id!);
      setAnchorItem(null);
      setSelectedDocumentIds(new Set());
      setSelectedTransactionIds(new Set());
    } finally {
      setLoading((existing) => {
        const newSet = new Set(existing);
        for (const loading of loadingIds) {
          newSet.delete(loading);
        }
        return newSet;
      });
      setAutoConfirmDialogOpen(false);
    }
  };

  if (!organizations) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Matches' />
        <PageBody gutter='thin' style={{ alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </PageBody>
      </PageContainer>
    );
  }

  let selectedLoading = false;
  for (const selectedDocument of selectedDocumentIds) {
    if (loading.has(selectedDocument)) {
      selectedLoading = true;
    }
  }
  for (const selectedTransaction of selectedTransactionIds) {
    if (loading.has(selectedTransaction)) {
      selectedLoading = true;
    }
  }

  const matchingMenu = (
    <Stack>
      <Stack direction='row'>
        <Stack alignItems='center'>
          <Typography variant='h4'>Transaction(s)</Typography>
          <Typography>{selectedTransactionIds.size} Selected</Typography>
        </Stack>
        <Stack alignItems='center'>
          <Typography variant='h4'>Document(s)</Typography>
          <Typography>{selectedDocumentIds.size} Selected</Typography>
        </Stack>
      </Stack>
      {autoMatchScoringLoading && (
        <Stack alignItems='center' justifyContent='center'>
          <CircularProgress />
        </Stack>
      )}
      {autoMatchScorePreview && (
        <table>
          <thead>
            <tr>
              <th align='left'>Property</th>
              <th align='right'>Score</th>
              <th align='right'>Weight</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={3}>
                <Divider />
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant='small'>Name</Typography>
              </td>
              <td align='right'>
                <Typography variant='small'>{(autoMatchScorePreview.scores.name * 100).toPrecision(3)}%</Typography>
              </td>
              <td align='right'>
                <Typography variant='small'>{(autoMatchScorePreview.weights.name * 100).toPrecision(3)}%</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant='small'>Date</Typography>
              </td>
              <td align='right'>
                <Typography variant='small'>{(autoMatchScorePreview.scores.date * 100).toPrecision(3)}%</Typography>
              </td>
              <td align='right'>
                <Typography variant='small'>{(autoMatchScorePreview.weights.date * 100).toPrecision(3)}%</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant='small'>Amount</Typography>
              </td>
              <td align='right'>
                <Typography variant='small'>{(autoMatchScorePreview.scores.amount * 100).toPrecision(3)}%</Typography>
              </td>
              <td align='right'>
                <Typography variant='small'>{(autoMatchScorePreview.weights.amount * 100).toPrecision(3)}%</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant='small'>Match Hints</Typography>
              </td>
              <td align='right'>
                <Typography variant='small'>{(autoMatchScorePreview.scores.matchHints * 100).toPrecision(3)}%</Typography>
              </td>
              <td align='right'>
                <Typography variant='small'>{(autoMatchScorePreview.weights.matchHints * 100).toPrecision(3)}%</Typography>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Divider />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                <Typography variant='h5'>Overall</Typography>
              </td>
              <td align='right'>
                <Typography variant='h5'>{(autoMatchScorePreview.overall * 100).toPrecision(3)}%</Typography>
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      )}
      <Stack>
        {!!matchGroupsByDocumentId[Array.from(selectedDocumentIds)[0]]?.totalMismatchResolution && (
          <Typography>Total Mismatch Resolution: {matchGroupsByDocumentId[Array.from(selectedDocumentIds)[0]].totalMismatchResolution}</Typography>
        )}
        {selectedLoading && (
          <Stack alignItems='center' justifyContent='center'>
            <CircularProgress />
          </Stack>
        )}
        {!selectedLoading && mode === Mode.EDIT && MatchingTab.UNDER_REVIEW === tab && (
          <Button
            fullWidth
            variant='contained'
            color='primary'
            disabled={selectedTransactionIds.size === 0 || selectedDocumentIds.size === 0 || selectedLoading}
            onClick={markAsReviewed}
          >
            Mark as Reviewed
          </Button>
        )}
        {!selectedLoading && mode === Mode.EDIT && (MatchingTab.UNMATCHED === tab || (MatchingTab.MATCHED === tab && matchChanged)) && (
          <>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              disabled={selectedTransactionIds.size === 0 || selectedDocumentIds.size === 0 || selectedLoading}
              onClick={() => saveMatches({ ignoreWarnings: false })}
            >
              Save
            </Button>
            <MatchConfirmDialog
              openFor={showConfirmMatchDifferenceDialogFor}
              transactions={(transactions || []).filter((t) => selectedTransactionIds.has(t.id))}
              documents={(documents || []).filter((d) => selectedDocumentIds.has(d.id))}
              onClose={() => setShowConfirmMatchDifferenceDialogFor(null)}
              onConfirm={(resolution) => saveMatches({ ignoreWarnings: true, totalMismatchResolution: resolution || undefined })}
            />
          </>
        )}
        {!selectedLoading && mode === Mode.EDIT && ((MatchingTab.MATCHED === tab && !matchChanged) || MatchingTab.UNDER_REVIEW === tab) && (
          <Button
            fullWidth
            variant='outlined'
            color='error'
            disabled={selectedTransactionIds.size === 0 || selectedDocumentIds.size === 0 || selectedLoading || dirtyEdit}
            onClick={() => deleteMatches()}
          >
            <Delete />
          </Button>
        )}
      </Stack>
    </Stack>
  );

  return (
    <PageContainer {...props}>
      <PageHeader title='Admin - Matches' />
      <PageBody gutter='thin'>
        <Stack height='100%'>
          <Stack direction='row' justifyContent='space-between'>
            <Stack direction='row' paddingTop={theme.spacing(2)}>
              <AdminOrganizationSelect
                organizations={organizations}
                onOrganizationChange={(org) => {
                  setSelectedOrg(org);
                  if (selectedOrg?.id !== previousSelectedOrganization?.id) {
                    setSelectedJournal(null);
                    setSelectedDocumentIds(new Set());
                    setSelectedTransactionIds(new Set());
                    setAnchorItem(null);
                  }
                }}
              />

              <AdminJournalSelect
                journals={journals}
                organization={selectedOrg}
                selectedJournal={selectedJournal}
                onJournalChange={(j) => {
                  setSelectedJournal(j);
                  setSelectedDocumentIds(new Set());
                  setSelectedTransactionIds(new Set());
                  setAnchorItem(null);
                }}
              />
            </Stack>

            <Stack direction='row' paddingTop={theme.spacing(2)}>
              <Tooltip title='Auto-match'>
                <Button variant='outlined' color='primary' onClick={() => setAutoConfirmDialogOpen(true)}>
                  <AutoMode />
                </Button>
              </Tooltip>
              <ConfirmDialog
                message='Running auto-matching cannot be reversed.'
                open={autoConfirmDialogOpen}
                onClose={() => setAutoConfirmDialogOpen(false)}
                onConfirm={executeAutoMatch}
              />

              <ButtonGroup style={{ justifyContent: 'end' }}>
                <MuiButton variant={mode === Mode.VIEW ? 'contained' : 'outlined'} onClick={() => changeMode(Mode.VIEW)}>
                  View
                </MuiButton>
                <MuiButton variant={mode === Mode.EDIT ? 'contained' : 'outlined'} onClick={() => changeMode(Mode.EDIT)}>
                  Edit
                </MuiButton>
              </ButtonGroup>
            </Stack>
          </Stack>

          <Tabs
            value={tab}
            onChange={(_e, newValue: MatchingTab) => {
              setAnchorItem(null);
              setSelectedDocumentIds(() => new Set<string>());
              setSelectedTransactionIds(() => new Set<string>());
              setTab(newValue);
            }}
          >
            <Tab label='Unmatched' value={MatchingTab.UNMATCHED} />
            <Tab label='Under Review' value={MatchingTab.UNDER_REVIEW} />
            <Tab label='Matched' value={MatchingTab.MATCHED} />
            <Tab label='All' value={MatchingTab.ALL} />
          </Tabs>

          <Stack direction='row' justifyContent='stretch' style={{ minHeight: 0, flex: 1 }}>
            <ItemList
              itemsLoading={transactionItemsLoading}
              loadingItems={loading}
              organization={selectedOrg}
              journal={selectedJournal}
              selected={selectedTransactionIds}
              onItemSelect={(item) => selectTransaction(item.id)}
              onItemDeselect={(item) => deselectTransaction(item.id)}
              filteringFn={itemFilterFn}
              title='Transactions'
              items={transactionItems || []}
              ref={transactionListRef}
              anchorItem={anchorItem?.id || null}
              matchingMenu={matchingMenu}
              actions={(item) => {
                const transactionLoading = loading.has(item.id);
                return (
                  <>
                    <Tooltip title={item.ignoreMatchRequirement ? 'Unignore Document Requirement' : 'Ignore Document Requirement'}>
                      <span>
                        <IconButton
                          disabled={
                            transactionLoading ||
                            (!transactionsMissingDocuments.has(item.id) && !item.ignoreMatchRequirement && !matchGroupsByTransactionId[item.id])
                          }
                          onClick={() => setConfirmIgnoreDocumentRequirementFor(item)}
                        >
                          {!item.ignoreMatchRequirement && (
                            <svg
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '1rem',
                                height: '1rem',
                              }}
                              viewBox='0 0 100 100'
                            >
                              <line
                                x1='100'
                                y1='10'
                                x2='10'
                                y2='100'
                                strokeWidth='1px'
                                stroke={theme.palette.background.default}
                                vectorEffect='non-scaling-stroke'
                              />
                              <line
                                x1='100'
                                y1='0'
                                x2='0'
                                y2='100'
                                strokeWidth='1px'
                                stroke={
                                  transactionLoading ||
                                  (!transactionsMissingDocuments.has(item.id) && !item.ignoreMatchRequirement && !matchGroupsByTransactionId[item.id])
                                    ? theme.palette.text.disabled
                                    : theme.palette.primary.main
                                }
                                vectorEffect='non-scaling-stroke'
                              />
                            </svg>
                          )}
                          <DocumentText1
                            size='1rem'
                            variant='Bold'
                            color={
                              transactionLoading ||
                              (!transactionsMissingDocuments.has(item.id) && !item.ignoreMatchRequirement && !matchGroupsByTransactionId[item.id])
                                ? theme.palette.text.disabled
                                : theme.palette.primary.main
                            }
                          />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip title='View Transaction'>
                      {transactionLoading ? (
                        <CircularProgress size='1.1rem' style={{ margin: '8px' }} />
                      ) : (
                        <span>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(`/admin/transactions?tab=REVIEWED&transactionIds=${item.id}`, '_blank');
                            }}
                          >
                            <Eye variant='Bold' size='1.1rem' color={theme.palette.primary.main} />
                          </IconButton>
                        </span>
                      )}
                    </Tooltip>
                  </>
                );
              }}
            />
            <ConfirmDialog
              open={!!confirmIgnoreDocumentRequirementFor}
              onClose={() => setConfirmIgnoreDocumentRequirementFor(null)}
              onConfirm={async () => {
                setConfirmIgnoreDocumentRequirementFor(null);
                await ignoreDocumentMatchRequirement(confirmIgnoreDocumentRequirementFor!.id);
              }}
              message={`Ignoring the document requirement for "${
                confirmIgnoreDocumentRequirementFor?.name || ''
              }" will allow this transaction to go unmatched. Are you sure?`}
            />
            <ConfirmDialog
              open={!!confirmIgnoreTransactionRequirementFor}
              onClose={() => setConfirmIgnoreTransactionRequirementFor(null)}
              onConfirm={async () => {
                setConfirmIgnoreTransactionRequirementFor(null);
                await ignoreTransactionMatchRequirement(confirmIgnoreTransactionRequirementFor!.id);
              }}
              message={`Ignoring the transaction requirement for "${
                confirmIgnoreTransactionRequirementFor?.name || ''
              }" will allow this document to go unmatched. Are you sure?`}
            />

            <ItemList
              itemsLoading={documentsItemsLoading}
              loadingItems={loading}
              organization={selectedOrg}
              journal={selectedJournal}
              selected={selectedDocumentIds}
              onItemSelect={(item) => selectDocument(item.id)}
              onItemDeselect={(item) => deselectDocument(item.id)}
              filteringFn={itemFilterFn}
              title='Documents'
              items={documentItems || []}
              ref={documentListRef}
              anchorItem={anchorItem?.id || null}
              matchingMenu={matchingMenu}
              actions={(item) => {
                const documentLoading = loading.has(item.id);
                return (
                  <>
                    <Tooltip title={item.ignoreMatchRequirement ? 'Unignore Transaction Requirement' : 'Ignore Transaction Requirement'}>
                      <span>
                        <IconButton
                          disabled={
                            documentLoading ||
                            (!documentsMissingTransactions.has(item.id) && !item.ignoreMatchRequirement && !matchGroupsByDocumentId[item.id])
                          }
                          onClick={() => setConfirmIgnoreTransactionRequirementFor(item)}
                        >
                          {!item.ignoreMatchRequirement && (
                            <svg
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '1rem',
                                height: '1rem',
                              }}
                              viewBox='0 0 100 100'
                            >
                              <line
                                x1='100'
                                y1='10'
                                x2='10'
                                y2='100'
                                strokeWidth='1px'
                                stroke={theme.palette.background.default}
                                vectorEffect='non-scaling-stroke'
                              />
                              <line
                                x1='100'
                                y1='0'
                                x2='0'
                                y2='100'
                                strokeWidth='1px'
                                stroke={
                                  documentLoading ||
                                  (!documentsMissingTransactions.has(item.id) && !item.ignoreMatchRequirement && !matchGroupsByDocumentId[item.id])
                                    ? theme.palette.text.disabled
                                    : theme.palette.primary.main
                                }
                                vectorEffect='non-scaling-stroke'
                              />
                            </svg>
                          )}
                          <DocumentText1
                            size='1rem'
                            variant='Bold'
                            color={
                              documentLoading ||
                              (!documentsMissingTransactions.has(item.id) && !item.ignoreMatchRequirement && !matchGroupsByDocumentId[item.id])
                                ? theme.palette.text.disabled
                                : theme.palette.primary.main
                            }
                          />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip title='View Document'>
                      {documentLoading ? (
                        <CircularProgress size='1.1rem' style={{ margin: '8px' }} />
                      ) : (
                        <span>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(`/admin/documents?tab=REVIEWED&documentIds=${item.id}`, '_blank');
                            }}
                          >
                            <Eye variant='Bold' size='1.1rem' color={theme.palette.primary.main} />
                          </IconButton>
                        </span>
                      )}
                    </Tooltip>
                  </>
                );
              }}
            />
          </Stack>
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
